import { PreviewerApiKey, EditorCoreApiKey, EditorAPIKey } from '@/apis';
import { PreviewerStore } from './previewerStore';
import { PreviewerApi, init } from './previewerApi';
import { BaseEntryScope, createEntryPoint } from '@/apilib';

export class Scope extends BaseEntryScope {
  editorCoreApi = this.useDependency(EditorCoreApiKey);
  editorAPI = this.useDependency(EditorAPIKey);
  store = this.declareStore(PreviewerStore);
}

export const PreviewerEntryPoint = createEntryPoint({
  name: 'Previewer',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(PreviewerApiKey, PreviewerApi);
  },
  async initialize(scope: Scope) {
    const { editorCoreApi } = scope;
    await editorCoreApi.hooks.initReady.promise;
    await init(scope);
  },
});
