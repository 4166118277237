import type {
  KitDefinition,
  KitPresetStructures,
  KitSiteStructure,
} from '../../types';
import { LUMINOSITY_THRESHOLD, THEME_COLOR_1, THEME_COLOR_2 } from './consts';
import {
  computeAverageLuminosity,
  computeLuminosity,
  hexToRgb,
} from './luminosity';
import {
  getComponentsOnImageBG,
  setButtonColor,
  setTextColor,
} from './structureUtils';

export const adjustColorsForImageBG = async (
  siteStructure: KitSiteStructure,
  kitDefinition: KitDefinition,
  staticMediaUrl: string,
): Promise<KitSiteStructure> => {
  const { darkColor, lightColor } = getColorsFromKit(kitDefinition);
  const [header, sections, footer] = await Promise.all([
    siteStructure.header
      ? adjustTextAndButtonColorsForStructure(
          siteStructure.header,
          kitDefinition,
          darkColor,
          lightColor,
          staticMediaUrl,
        )
      : null,

    Promise.all(
      siteStructure.sections.map((section) =>
        adjustTextAndButtonColorsForStructure(
          section,
          kitDefinition,
          darkColor,
          lightColor,
          staticMediaUrl,
        ),
      ),
    ),

    siteStructure.footer
      ? adjustTextAndButtonColorsForStructure(
          siteStructure.footer,
          kitDefinition,
          darkColor,
          lightColor,
          staticMediaUrl,
        )
      : null,
  ]);
  return { header, sections, footer };
};

const adjustTextAndButtonColorsForStructure = async (
  section: KitPresetStructures,
  kitDefinition: KitDefinition,
  darkColor: string,
  lightColor: string,
  staticMediaUrl: string,
): Promise<KitPresetStructures> => {
  const elements = getComponentsOnImageBG(section.structure, kitDefinition);
  for (const url of Object.keys(elements)) {
    if (
      elements[url].texts.length === 0 &&
      elements[url].buttons.length === 0
    ) {
      continue;
    }

    let [mainColor, secondaryColor] = [lightColor, darkColor];

    if (!elements[url].transparentImage) {
      const avgLuminosity = await computeAverageLuminosity(
        `${staticMediaUrl}/${url}`,
      );
      [mainColor, secondaryColor] =
        avgLuminosity > LUMINOSITY_THRESHOLD
          ? [darkColor, lightColor]
          : [lightColor, darkColor];
    }

    elements[url].texts.forEach((text) => {
      setTextColor(text, mainColor);
    });
    elements[url].buttons.forEach((button) => {
      setButtonColor(button, kitDefinition, mainColor, secondaryColor);
    });
  }
  return section;
};

const getColorsFromKit = (
  kitDefinition: KitDefinition,
): {
  darkColor: string;
  lightColor: string;
} => {
  const color1 = kitDefinition.colors[THEME_COLOR_1];
  const color2 = kitDefinition.colors[THEME_COLOR_2];

  if (
    computeLuminosity(hexToRgb(color1)) < computeLuminosity(hexToRgb(color2))
  ) {
    return { darkColor: THEME_COLOR_1, lightColor: THEME_COLOR_2 };
  }
  return { darkColor: THEME_COLOR_2, lightColor: THEME_COLOR_1 };
};
