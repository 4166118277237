import React, { type JSXElementConstructor } from 'react';
import _ from 'lodash';
import { MENU_ITEMS_TO_CREATE } from './consts';
import { BasePublicApi } from '@/apilib';
import type { Scope } from './previewerEntryPoint';
import type {
  DocumentServicesObject,
  StyleRef,
  TextThemeMap,
  ColorPalette,
  CompStructure,
  PagesStructure,
  PageRef,
  CompRef,
  MiniSiteManager,
  MenuObject,
} from '@wix/document-services-types';
import { PreviewerItem } from './components/previewerItem';
import { ErrorReporter } from '@wix/editor-error-reporter';

import type { DisplayConfig } from './components/previewerItem';

let isAlreadyInitiated = false;
export const initSetup = (miniSiteManager: MiniSiteManager) => {
  if (isAlreadyInitiated) return;
  isAlreadyInitiated = true;
  miniSiteManager.setHostReact(React);

  miniSiteManager.ds.pages.add(
    `componentsPageContainer-${Date.now()}`,
    undefined,
    false,
  );

  MENU_ITEMS_TO_CREATE.forEach((menuType: string) => {
    if (miniSiteManager.ds.menu.getById(menuType)) {
      return;
    }
    miniSiteManager.ds.menu.create(
      {
        items: [],
        syncWithPages: false,
        type: 'CustomMenu',
        menuType,
      },
      // TODO: remove when bump ds-types
      // @ts-expect-error
      menuType,
    );
  });
};

export async function init(scope: Scope) {
  const { store } = scope;
  const previewFrame = window.document.querySelector('#preview');

  if (previewFrame) {
    if (!(previewFrame as any).contentWindow.miniSiteManager) {
      console.log('this dm version has no miniSiteManager');
      return;
    }

    await (previewFrame as any).contentWindow.miniSiteManager.waitForLoad();
    const miniSiteManager = (previewFrame as any).contentWindow.miniSiteManager;
    if (!miniSiteManager.ds) {
      ErrorReporter.captureMessage('error loading ds to miniSiteManager');
      return;
    }

    store.setMiniSiteManager(miniSiteManager);
    initSetup(miniSiteManager);
  }
}

function updateTheme(
  miniDs: DocumentServicesObject,
  customSiteTheme: SiteTheme,
) {
  const miniSiteDsThemeApi = miniDs.theme;

  const newColors = customSiteTheme?.colors;
  miniSiteDsThemeApi.colors.update(newColors);

  Object.entries(customSiteTheme?.styles)
    .filter(([_, styleData]) => styleData?.styleType === 'system')
    .forEach(([styleId, styleValue]) => {
      miniSiteDsThemeApi.styles.update(styleId, styleValue);
    });

  const newTextThemes = customSiteTheme?.textThemes;
  miniSiteDsThemeApi.textThemes.update(newTextThemes);
}

const findMainMenuItem = (menuObject: MenuObject) =>
  menuObject.getAll().find(({ id }) => id === 'CUSTOM_MAIN_MENU');

const updateMainMenuItems = (
  miniDS: DocumentServicesObject,
  menuItemsNames: string[],
) => {
  const miniSiteDsMenuApi = miniDS.menu;
  const mainMenuItem = findMainMenuItem(miniSiteDsMenuApi);
  if (mainMenuItem) {
    const allMainMenuItemsLabels = mainMenuItem.items.map(({ label }) => label);
    menuItemsNames.forEach((menuItemName) => {
      if (!allMainMenuItemsLabels.includes(menuItemName))
        miniSiteDsMenuApi.addItem(mainMenuItem.id, { label: menuItemName });
    });
  }
};

const updateHomePageName = (
  miniDS: DocumentServicesObject,
  homePageName: string,
) => {
  const miniSiteDsMenuApi = miniDS.menu;
  const mainMenuItem = findMainMenuItem(miniSiteDsMenuApi);
  if (mainMenuItem) {
    miniSiteDsMenuApi.updateItem(mainMenuItem.id, mainMenuItem.items[0].id, {
      label: homePageName,
    });
  }
};

const addPage = (
  miniDs: DocumentServicesObject,
  pageStructure: PagesStructure,
) => {
  delete pageStructure.data.pageUriSEO;
  const pageRef: PageRef = miniDs.pages.add(
    `page-thumbnail-${Date.now()}`,
    pageStructure,
    false,
  );

  return pageRef;
};

const addAsPage = (
  miniDs: DocumentServicesObject,
  compStructure: CompStructure,
  isMobile: boolean,
  compsOldToNewIdsMap?: Record<string, string>,
) => {
  const pageRef: PageRef = miniDs.pages.add(
    `thumbnail-${Date.now()}`,
    undefined,
    false,
  );
  compStructure.layout.y = 0;
  const ref = isMobile ? miniDs.components.getMobileRef(pageRef) : pageRef;
  miniDs.components.add(
    ref,
    compStructure,
    '',
    null,
    // @ts-expect-error
    compsOldToNewIdsMap,
  );
  return pageRef;
};

async function createThumbnailComponentsFromThumbnailIds(
  createPreviewElements: MiniSiteManager['createPreviewElements'],
  thumbnailIds: PageRef[] | CompRef[],
  sourceTemplateId?: string,
): Promise<Array<JSXElementConstructor<{ onReady: Function }>>> {
  return createPreviewElements(thumbnailIds, {
    applicationCSMTemplateID: sourceTemplateId,
  });
}

export interface PreviewData extends DisplayConfig {
  structure: CompStructure | PagesStructure;
  compsOldToNewIdsMap?: Record<string, string>;
}

export interface SiteTheme {
  textThemes: TextThemeMap;
  colors: ColorPalette;
  styles: Record<string, StyleRef>;
}

export interface CreatePreviewComponentsParams {
  previewsData: PreviewData[];
  scaleValue?: number;
  customSiteTheme?: SiteTheme;
  sourceTemplateId?: string;
  isMobile?: boolean;
  customHomePageName?: string;
  customMenuItemsNames?: string[];
  enableVideoPlaying?: boolean;
  onReady?: () => void;
}

interface PreviewsMap {
  [key: string]: PreviewData;
}

async function addComponentsForPreview(
  miniDs: DocumentServicesObject,
  previewsData: PreviewData[],
  isMobile: boolean,
) {
  const previewsMap = previewsData?.reduce(
    (previewsMap: PreviewsMap, previewData) => {
      const { structure } = previewData;
      const clonedStructure = _.cloneDeep(structure);
      const isPage = structure.type === 'Page';

      fixStructureLayout(clonedStructure);

      if (isPage) {
        const { id } = addPage(miniDs, clonedStructure as PagesStructure);
        previewsMap[id] = previewData;
      } else {
        const { id } = addAsPage(
          miniDs,
          clonedStructure as CompStructure,
          isMobile,
          previewData.compsOldToNewIdsMap,
        );
        previewsMap[id] = previewData;
      }
      return previewsMap;
    },
    {},
  );

  await miniDs.waitForChangesAppliedAsync();

  return previewsMap;
}
// When component has fixedPosition===true -> component doesn't take up space in
// ThumbnailPreview & wrapper divs receive incorrect height
const fixStructureLayout = (structure: CompStructure | PagesStructure) => {
  structure.layout.fixedPosition = false;
};

export async function createPreviewComponents(
  miniDs: DocumentServicesObject,
  createPreviewElements: MiniSiteManager['createPreviewElements'],
  {
    previewsData,
    scaleValue = 1,
    customSiteTheme,
    sourceTemplateId,
    isMobile = false,
    customHomePageName,
    customMenuItemsNames,
    enableVideoPlaying = true,
    onReady,
  }: CreatePreviewComponentsParams,
): Promise<React.ReactElement[]> {
  updateTheme(miniDs, customSiteTheme);
  if (customHomePageName) {
    updateHomePageName(miniDs, customHomePageName);
  }
  if (customMenuItemsNames) {
    updateMainMenuItems(miniDs, customMenuItemsNames);
  }

  miniDs.documentMode.enablePlaying(Boolean(enableVideoPlaying));
  miniDs.documentMode.setImageTargetScale(scaleValue);
  let thumbnailPointers = [] as PageRef[];
  let thumbnailComponents: Array<JSXElementConstructor<{ onReady: Function }>> =
    [];

  const viewMode = isMobile
    ? miniDs.viewMode.VIEW_MODES.MOBILE
    : miniDs.viewMode.VIEW_MODES.DESKTOP;

  miniDs.viewMode.set(viewMode);
  await miniDs.waitForChangesAppliedAsync();
  await miniDs.transactions.run(async () => {
    const previewsMap = await addComponentsForPreview(
      miniDs,
      previewsData,
      isMobile,
    );
    thumbnailPointers = Object.keys(previewsMap).map(
      (id) => ({ id, type: viewMode }) as PageRef,
    );

    thumbnailComponents = await createThumbnailComponentsFromThumbnailIds(
      createPreviewElements,
      thumbnailPointers,
      sourceTemplateId,
    );
  });

  thumbnailPointers.forEach((thumbnailPointer) => {
    if (miniDs.utils.isPage(thumbnailPointer)) {
      miniDs.pages.remove(thumbnailPointer.id);
    }
  });
  return thumbnailComponents.map((thumbnailComponent, i) => (
    <PreviewerItem
      key={thumbnailPointers[i].id}
      ThumbnailComponent={thumbnailComponent}
      {...previewsData[i]}
      onReady={onReady || previewsData[i].onReady}
    />
  ));
}

const getDefaultTheme = (editorAPI: Scope['editorAPI']): SiteTheme => ({
  textThemes: editorAPI.theme.textThemes.getAll(),
  colors: editorAPI.theme.colors.getAll() as ColorPalette,
  styles: editorAPI.theme.styles.getAll(),
});

const createPreviewComponentsWithScope = async (
  { store, editorAPI }: Scope,
  params: CreatePreviewComponentsParams,
): Promise<React.ReactElement[]> => {
  const { ds, createPreviewElements } = store.getMiniSiteManager();

  if (!params.customSiteTheme) {
    params.customSiteTheme = getDefaultTheme(editorAPI);
  }
  return createPreviewComponents(ds, createPreviewElements, params);
};

export const prefetchCustomCSM = async (
  miniDs: DocumentServicesObject,
  publishedTemplateMetasiteId: string,
): Promise<void> => {
  if (!publishedTemplateMetasiteId) return;
  try {
    await miniDs.miniSite.loadCSM(publishedTemplateMetasiteId);
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: { minisite: true, loadCustomCsm: true },
      extra: { publishedTemplateMetasiteId },
    });
    console.error(e);
  }
};

const prefetchCustomCSMWithScope = async (
  { store }: Scope,
  publishedTemplateMetasiteId: string,
): Promise<void> => {
  const { ds } = store.getMiniSiteManager();
  await prefetchCustomCSM(ds, publishedTemplateMetasiteId);
};

export class PreviewerApi extends BasePublicApi<Scope> {
  prefetchCustomCSM = this.bindScope(prefetchCustomCSMWithScope);
  createPreviewComponents = this.bindScope(createPreviewComponentsWithScope);
}
