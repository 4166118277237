import { STORES_CE_TYPES } from '../consts';
import { fetchPresetStructures } from './fetchDataUtils';
import {
  adjustHeaderWidgets,
  adjustHeaderWidgetsByRootApp,
} from './headerWidgetsUtils';
import {
  WIX_GET_SUBSCRIBERS,
  WIX_RESTAURANTS_ORDERS_NEW,
} from '@wix/app-definition-ids';

import type { PageSectionContent } from '@wix/editor-content-provider';
import type { SerializedCompStructure } from '@wix/document-services-types';
import {
  FooterPresetStructure,
  HeaderPresetStructure,
  FooterPresetDefinition,
  HeaderPresetDefinition,
  Dimensions,
} from '../types';
import { COMP_TYPES, logoAlignmentOptions } from '../siteGeneratorUtilsConsts';
import { transformCompStructure } from './siteGeneratorUtils';
import { PresetContentFieldRole } from '@wix/editor-content-injector';
import {
  WIX_BLOG,
  WIX_STORES,
  WIX_SITE_SEARCH,
  MEMBERS_AREA,
  WIX_NEW_STORES,
  WIX_BOOKINGS,
  WIX_EVENTS,
} from '@wix/app-definition-ids';

const getWidgetToRootAppMap = (
  isUsingSgOrigin: boolean,
): Record<string, string[]> => ({
  [WIX_SITE_SEARCH]: [WIX_BLOG],
  [WIX_STORES]: [WIX_NEW_STORES, WIX_RESTAURANTS_ORDERS_NEW],
  [MEMBERS_AREA]: isUsingSgOrigin
    ? [
        WIX_NEW_STORES,
        WIX_BLOG,
        WIX_BOOKINGS,
        WIX_EVENTS,
        WIX_RESTAURANTS_ORDERS_NEW,
      ]
    : [WIX_RESTAURANTS_ORDERS_NEW],
});

export const getRelevantWidgetIdsFromChosenApps = (
  chosenApps: string[],
  isUsingSgOrigin: boolean,
): string[] => {
  const widgetToRootAppMap = getWidgetToRootAppMap(isUsingSgOrigin);
  return Object.entries(widgetToRootAppMap).reduce(
    (acc: string[], [widgetId, rootApps]) => {
      if (rootApps.some((rootApp) => chosenApps.includes(rootApp))) {
        acc.push(widgetId);
      }
      return acc;
    },
    [],
  );
};

const getHeaderWidgetsCountScore = (
  relevantWidgetIdsFromChosenApps: string[],
  headerAppIds: string[],
) => {
  return headerAppIds.reduce((acc, widgetId) => {
    if (relevantWidgetIdsFromChosenApps.includes(widgetId)) {
      acc++;
    } else {
      acc -= 100; //some big number which is bigger than count of possible widgets
    }
    return acc;
  }, 0);
};

const buildMatchScoreHeadersByWidgets = (
  relevantWidgetIdsFromChosenApps: string[],
  headerList: HeaderPresetDefinition[],
): Record<string, number> => {
  const matchScoreHeaders = {};
  headerList.forEach((header) => {
    matchScoreHeaders[header._id] = getHeaderWidgetsCountScore(
      relevantWidgetIdsFromChosenApps,
      header.appIds,
    );
  });
  return matchScoreHeaders;
};

const filterByWidgetsToInstalledAppsMatching = (
  chosenApps: string[],
  logoFilteredHeadersList: HeaderPresetDefinition[],
  isUsingSgOrigin: boolean,
) => {
  const relevantWidgetIdsFromChosenApps = getRelevantWidgetIdsFromChosenApps(
    chosenApps,
    isUsingSgOrigin,
  );
  const matchScoreHeaders = buildMatchScoreHeadersByWidgets(
    relevantWidgetIdsFromChosenApps,
    logoFilteredHeadersList,
  );
  const highestMatchScore = Math.max(...Object.values(matchScoreHeaders));
  return logoFilteredHeadersList.filter(
    (header) => matchScoreHeaders[header._id] === highestMatchScore,
  );
};

export const filterHeaders = (
  headersList: HeaderPresetDefinition[],
  hasLogo: boolean,
  chosenApps: string[],
  isUsingSgOrigin: boolean,
): HeaderPresetDefinition[] => {
  const logoFilteredHeadersList = headersList.filter(
    (header) => header.hasLogo === hasLogo,
  );
  return filterByWidgetsToInstalledAppsMatching(
    chosenApps,
    logoFilteredHeadersList,
    isUsingSgOrigin,
  );
};

export const adjustHeader = (
  headerStructure: SerializedCompStructure,
  isAppInstalled: (appDefId: string) => boolean,
): SerializedCompStructure => {
  return adjustHeaderWidgets(isAppInstalled, headerStructure);
};

// Currently header needs only stores sourceTemplateId, so this is a specific handling for that case.
function getHeaderSourceTemplateId(
  appsToInstall: string[],
  sectionContents: PageSectionContent[],
): string | undefined {
  if (!appsToInstall.includes(WIX_NEW_STORES)) {
    return undefined;
  }
  return sectionContents.find(({ ceType }) => STORES_CE_TYPES.includes(ceType))
    ?.sourceTemplateId;
}

export const getHeaderStructure = async (
  headerPreset: HeaderPresetDefinition | null,
  appsToInstall: string[],
  sectionContents: PageSectionContent[],
): Promise<HeaderPresetStructure | null> => {
  if (!headerPreset) return null;
  const {
    presetJsonUrl,
    presetMobileJsonUrl,
    title,
    _id,
    logoAlignment,
    mobileLogoAlignment,
  } = headerPreset;
  const [structure, mobileStructure] = await fetchPresetStructures([
    presetJsonUrl,
    presetMobileJsonUrl,
  ]);
  const headerDesktopStructure = adjustHeaderWidgetsByRootApp(
    structure,
    appsToInstall,
  );
  const sourceTemplateId = getHeaderSourceTemplateId(
    appsToInstall,
    sectionContents,
  );
  return {
    title,
    id: _id,
    ceType: undefined,
    structure: headerDesktopStructure,
    mobileStructure,
    contentCategory: undefined,
    sourceTemplateId,
    logoAlignment,
    mobileLogoAlignment,
  };
};

export const filterFooters = (
  footersList: FooterPresetDefinition[],
  hasLogo: boolean,
  appDefIds: string[],
): FooterPresetDefinition[] => {
  const isSubscribeAppInstalled = appDefIds.includes(WIX_GET_SUBSCRIBERS);
  return footersList.filter(
    (footer) =>
      footer.hasLogo === hasLogo &&
      footer.appIds.includes(WIX_GET_SUBSCRIBERS) === isSubscribeAppInstalled,
  );
};

export const adjustFooter = (
  footerStructure: SerializedCompStructure,
  socialLinks: Array<string>,
): SerializedCompStructure => {
  if (socialLinks.length === 0) {
    return transformCompStructure(
      footerStructure,
      (compStructure: SerializedCompStructure) =>
        removeSocialLinksBar(compStructure),
    );
  }
  return footerStructure;
};

export const getFooterStructure = async (
  footerPreset: FooterPresetDefinition | null,
  socialLinks: string[],
): Promise<FooterPresetStructure | null> => {
  if (!footerPreset) return null;
  const {
    presetJsonUrl,
    presetMobileJsonUrl,
    title,
    _id,
    logoAlignment,
    mobileLogoAlignment,
  } = footerPreset;
  const [footerDesktopStructure, mobileStructure] = await fetchPresetStructures(
    [presetJsonUrl, presetMobileJsonUrl],
  );
  const structure = adjustFooter(footerDesktopStructure, socialLinks);
  return {
    title,
    id: _id,
    ceType: undefined,
    structure,
    mobileStructure,
    contentCategory: undefined,
    sourceTemplateId: undefined,
    logoAlignment,
    mobileLogoAlignment,
  };
};

const removeSocialLinksBar = (compStructure: SerializedCompStructure) => {
  if (
    compStructure.components &&
    compStructure.componentType !== COMP_TYPES.SOCIAL_LINK_BAR
  ) {
    compStructure.components = compStructure.components?.filter((childComp) => {
      return childComp.componentType !== COMP_TYPES.SOCIAL_LINK_BAR;
    });
  }
  return compStructure;
};

export const collectLogoComponents = (
  compStructure: SerializedCompStructure,
  logoComponents: SerializedCompStructure[],
) => {
  if (compStructure.contentRole?.fieldRole === PresetContentFieldRole.Logo) {
    logoComponents.push(compStructure);
  }
  if (!compStructure.components) return;

  compStructure.components.forEach((childComp) => {
    collectLogoComponents(childComp, logoComponents);
  });
};

const adjustLogoComponentLayout = (
  logoCompStructure: SerializedCompStructure,
  logoAlignment: string,
  logoDimensions: Dimensions,
) => {
  if (!logoCompStructure.layout) return;

  const newWidth = Math.round(
    (logoCompStructure.layout.height / logoDimensions.height) *
      logoDimensions.width,
  );
  let layoutX;
  switch (logoAlignment) {
    case logoAlignmentOptions.Center:
      layoutX = Math.round(
        logoCompStructure.layout.x +
          (logoCompStructure.layout.width - newWidth) / 2,
      );
      break;
    case logoAlignmentOptions.Right:
      layoutX = Math.round(
        logoCompStructure.layout.x + logoCompStructure.layout.width - newWidth,
      );
      break;
    case logoAlignmentOptions.Left:
    default:
      layoutX = logoCompStructure.layout.x;
      break;
  }

  logoCompStructure.layout.width = newWidth;
  logoCompStructure.layout.x = layoutX;
};

export const adjustLogoLayout = (
  presetStructure: HeaderPresetStructure | FooterPresetStructure | null,
) => {
  if (!presetStructure) return;

  const desktopLogoComponents: SerializedCompStructure[] = [];
  const mobileLogoComponents: SerializedCompStructure[] = [];
  collectLogoComponents(presetStructure.structure, desktopLogoComponents);
  collectLogoComponents(presetStructure.mobileStructure, mobileLogoComponents);

  if (desktopLogoComponents.length === 0) return;

  const { width, height } = desktopLogoComponents[0].data;
  const logoDimensions: Dimensions = { width, height };

  desktopLogoComponents.forEach((logoCompStructure) => {
    adjustLogoComponentLayout(
      logoCompStructure,
      presetStructure.logoAlignment,
      logoDimensions,
    );
  });

  mobileLogoComponents.forEach((logoCompStructure) => {
    adjustLogoComponentLayout(
      logoCompStructure,
      presetStructure.mobileLogoAlignment,
      logoDimensions,
    );
  });
};
