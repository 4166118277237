import _ from 'lodash';
import {
  ALLOWED_COMPS_TO_UPDATE_PROPS,
  COMP_IDS_NOT_TO_COLLECT,
  FOOTER,
  HEADER,
  LAYOUT_PROPS_TO_OMIT,
  LAYOUT_PROPS_WITH_NUMERIC_VALUES,
  PROPS_KEYS_TO_UPDATE,
  SECTIONS,
} from './mobileLayoutAdjustmentConsts';
import { COMP_TYPES } from '../siteGeneratorUtilsConsts';

import type {
  CompLayout,
  CompRef,
  DefaultComponentProperties,
  DocumentServicesObject,
  SerializedCompStructure,
} from '@wix/document-services-types';
import type {
  CompIdToParentIdMap,
  ExtractedStructures,
  GeneratedHomepage,
  GeneratedPage,
  PagePartKeys,
  PresetIdToSiteIdMap,
} from '../types';
import type { ReportError } from '@wix/editor-content-injector';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { replaceDesign, replaceStyle } from '../utils/siteGeneratorUtils';

const FORM_COMPS = new Set<string>([
  COMP_TYPES.APP_WIDGET,
  COMP_TYPES.FORM_CONTAINER,
]);

const flattenCompStructureRecursively = (
  compStructures: SerializedCompStructure[],
  flatCompStructure: SerializedCompStructure[],
): void => {
  compStructures.forEach((compStructure) => {
    if (compStructure.id && !COMP_IDS_NOT_TO_COLLECT.has(compStructure.id)) {
      const singleCompStructure = _.omit(compStructure, 'components');
      flatCompStructure.push(singleCompStructure);
    }
    if (compStructure.components) {
      flattenCompStructureRecursively(
        compStructure.components,
        flatCompStructure,
      );
    }
  });
};

export const collectFlatCompList = (
  sections: SerializedCompStructure[],
): SerializedCompStructure[] => {
  const flatCompStructure: SerializedCompStructure[] = [];
  flattenCompStructureRecursively(sections, flatCompStructure);
  return flatCompStructure;
};

const getPresetIdToSiteIdRecursively = (
  ds: DocumentServicesObject,
  presetIdToSiteIdMap: PresetIdToSiteIdMap,
  presetRef: CompRef,
  desktopStructure: SerializedCompStructure,
  reportError: ReportError,
) => {
  if (!desktopStructure?.id || !presetRef?.id) {
    reportError(
      !desktopStructure?.id
        ? 'desktopStructure.id is missing'
        : 'presetRef.id is missing',
      {
        desktopStructureId: desktopStructure?.id,
        presetRef,
        presetIdToSiteIdMap,
      },
    );
    return;
  }

  presetIdToSiteIdMap[desktopStructure.id] = presetRef.id;

  ds.components.getChildren(presetRef).forEach((childRef, index) => {
    const comp = desktopStructure.components?.[index];
    if (!comp) {
      reportError('desktopStructure comp is missing', {
        presetRef,
        presetIdToSiteIdMap,
      });
      return;
    }
    getPresetIdToSiteIdRecursively(
      ds,
      presetIdToSiteIdMap,
      childRef,
      comp,
      reportError,
    );
  });
};

export const getSiteIdToPresetIdMap = (
  ds: DocumentServicesObject,
  pageRef: CompRef,
  pagePartKey: PagePartKeys,
  desktopStructures: SerializedCompStructure[],
  reportError: ReportError,
): PresetIdToSiteIdMap => {
  const presetIdToSiteIdMap = {};
  const parentsRefs = getParentsCompRefs(ds, pageRef, pagePartKey);
  desktopStructures.forEach((desktopStructure, index) => {
    getPresetIdToSiteIdRecursively(
      ds,
      presetIdToSiteIdMap,
      parentsRefs[index],
      desktopStructure,
      reportError,
    );
  });
  return presetIdToSiteIdMap;
};

export const getMobileCompRef = (
  ds: DocumentServicesObject,
  compId: string,
): CompRef | null => {
  return ds.components.get.byId(compId, undefined, 'MOBILE');
};

const getAllIds = (flatCompList: SerializedCompStructure[]): string[] => {
  return flatCompList.map(({ id }) => id).filter((id): id is string => !!id);
};

export const getType = (
  ds: DocumentServicesObject,
  compRef: CompRef,
): string => {
  return ds.components.is.exist(compRef) ? ds.components.getType(compRef) : '';
};

const isFormComponent = (
  ds: DocumentServicesObject,
  compId: string,
): boolean => {
  const compRef = getMobileCompRef(ds, compId);
  if (!compRef) return false;
  const compType = getType(ds, compRef);
  // TODO bad performance
  const [child] = ds.components.getChildren(compRef);
  const childType = getType(ds, child);
  // TODO bad performance
  const container = ds.components.getContainer(compRef);
  const containerType = getType(ds, container);
  return (
    FORM_COMPS.has(compType) ||
    FORM_COMPS.has(childType) ||
    FORM_COMPS.has(containerType)
  );
};

export const hideNeededComps = (
  ds: DocumentServicesObject,
  flatCompListDesktop: SerializedCompStructure[],
  flatCompListMobile: SerializedCompStructure[],
) => {
  const idsDesktop = getAllIds(flatCompListDesktop);
  const idsMobile = getAllIds(flatCompListMobile);
  const idsToHide = idsDesktop.filter(
    (idDesktop) => !idsMobile.includes(idDesktop),
  );
  for (const idToHide of idsToHide) {
    const compRef = getMobileCompRef(ds, idToHide);
    if (
      !compRef ||
      !ds.components.is.exist(compRef) ||
      !ds.components.is.hiddenable(compRef)
    ) {
      continue;
    }
    const containerId =
      // TODO bad performance
      ds.components.getContainer(compRef)?.id;
    if (
      !compRef ||
      idsToHide.includes(containerId) ||
      isFormComponent(ds, idToHide)
    ) {
      continue;
    }
    ds.mobile.hiddenComponents.hide(compRef);
  }
};

export const extractStructures = (
  fullSiteStructure: GeneratedHomepage | GeneratedPage,
  pagePartKey: PagePartKeys,
): ExtractedStructures => {
  const extractedStructures: ExtractedStructures = {
    structure: [],
    mobileStructure: [],
  };
  if (pagePartKey === SECTIONS) {
    return fullSiteStructure[pagePartKey].reduce(
      (acc, { structure, mobileStructure }) => {
        acc.structure.push(structure);
        acc.mobileStructure.push(mobileStructure);
        return acc;
      },
      extractedStructures,
    );
  } else if (fullSiteStructure[pagePartKey]) {
    const { structure, mobileStructure } = fullSiteStructure[pagePartKey]!;
    extractedStructures.structure.push(structure);
    extractedStructures.mobileStructure.push(mobileStructure);
  }
  return extractedStructures;
};

const updatePresetIdRecursively = (
  compStructures: SerializedCompStructure[],
  presetIdToSiteIdMap: PresetIdToSiteIdMap,
): void => {
  compStructures.forEach((compStructure) => {
    if (!compStructure?.id) {
      return;
    }
    const documentId = presetIdToSiteIdMap[compStructure.id];
    if (documentId) {
      compStructure.id = documentId;
    }
    if (compStructure?.parent) {
      const documentParentId = presetIdToSiteIdMap[compStructure.parent];
      if (documentParentId) {
        compStructure.parent = documentParentId;
      }
    }
    const metaDataId =
      // @ts-ignore
      presetIdToSiteIdMap[compStructure?.metaData?.originalCompId];
    if (metaDataId) {
      // @ts-ignore
      compStructure.metaData.originalCompId = metaDataId;
    }
    const mobileStructureId =
      presetIdToSiteIdMap[
        compStructure?.mobileStructure?.metaData?.originalCompId
      ];
    if (mobileStructureId) {
      compStructure.mobileStructure.metaData.originalCompId = mobileStructureId;
    }
    if (compStructure.components) {
      updatePresetIdRecursively(compStructure.components, presetIdToSiteIdMap);
    }
  });
};

export const updatePresetIds = (
  structure: SerializedCompStructure[],
  presetIdToSiteIdMap: PresetIdToSiteIdMap,
) => {
  const updatedStructure = _.cloneDeep(structure);
  updatedStructure.forEach((compStructure) => {
    updatePresetIdRecursively([compStructure], presetIdToSiteIdMap);
  });
  return updatedStructure;
};

export const removeNonNumericValues = (
  object: object,
  propsArrToCheck: string[],
): object => {
  return Object.entries(object).reduce((acc, [prop, value]) => {
    if (propsArrToCheck.includes(prop) && !_.isNumber(value)) {
      return acc;
    }
    acc[prop] = value;
    return acc;
  }, {});
};

export const updateLayoutIfNeeded = (
  ds: DocumentServicesObject,
  compRef: CompRef,
  newLayout: Partial<CompLayout>,
  reportError: ReportError,
) => {
  const currentLayout = ds.components.layout.get(compRef);
  if (!currentLayout) {
    return;
  }
  const newLayoutKeys = Object.keys(newLayout);
  const filteredCurrentLayout = _.pick(currentLayout, newLayoutKeys);
  if (_.isEqual(newLayout, filteredCurrentLayout)) {
    return;
  }
  const layoutToUpdate: Partial<CompLayout> = Object.fromEntries(
    Object.entries(newLayout).filter(([, layoutValue]) =>
      _.isNumber(layoutValue),
    ),
  );
  // TODO this is some weird bug where we get non numeric values in the layout obj in windows
  const newLayoutToUpdate = removeNonNumericValues(
    {
      ...currentLayout,
      ...layoutToUpdate,
    },
    LAYOUT_PROPS_WITH_NUMERIC_VALUES,
  );
  const filteredNewLayout = _.omit(newLayoutToUpdate, LAYOUT_PROPS_TO_OMIT);
  try {
    ds.components.layout.validateAndForceUpdateLayout(
      compRef,
      filteredNewLayout,
    );
  } catch (e: any) {
    reportError(`Error updating layout: ${e.message}`, {
      newLayoutToUpdate,
      compRef,
    });
  }
};

export const getPagePartsKeys = (
  fullSiteStructure: GeneratedHomepage | GeneratedPage,
): PagePartKeys[] => {
  const shouldRunOnHeaderFooter = isHomepage(fullSiteStructure);
  return [
    SECTIONS,
    ...(shouldRunOnHeaderFooter ? [HEADER, FOOTER] : []),
  ] as PagePartKeys[];
};

const pickPropsNotToUpdate = (
  props: DefaultComponentProperties,
): Partial<DefaultComponentProperties> => {
  return _.pick(props, PROPS_KEYS_TO_UPDATE);
};

export const updatePropertiesIfNeeded = (
  ds: DocumentServicesObject,
  compRef: CompRef,
  newProperties: any,
) => {
  const filteredNewProps = pickPropsNotToUpdate(newProperties);
  const newPropsKeys = Object.keys(filteredNewProps);
  if (_.isEmpty(newPropsKeys)) {
    return;
  }
  const currentProps = ds.components.properties.get(compRef);
  const filteredCurrentProps = _.pick(currentProps, newPropsKeys);
  if (_.isEqual(filteredNewProps, filteredCurrentProps)) {
    return;
  }
  const isForked = ds.components.properties.mobile.isForked(compRef);
  if (!isForked) {
    ds.components.properties.mobile.fork(compRef);
  }
  ds.components.properties.update(compRef, filteredNewProps);
};

export const applyPropertiesFromStructure = (
  ds: DocumentServicesObject,
  mobileStructures: SerializedCompStructure[],
) => {
  mobileStructures.forEach((mobileStructure) => {
    const noProps = _.isEmpty(mobileStructure.props);
    if (noProps || !mobileStructure.id) {
      return;
    }
    const compRef = getMobileCompRef(ds, mobileStructure.id);
    if (!compRef) {
      return;
    }
    const compType = getType(ds, compRef);
    if (!ALLOWED_COMPS_TO_UPDATE_PROPS.includes(compType)) {
      return;
    }
    updatePropertiesIfNeeded(ds, compRef, mobileStructure.props);
  });
};

export const getRenderedToParentMap = (
  ds: DocumentServicesObject,
  mobileCompRef: CompRef,
): CompIdToParentIdMap => {
  return ds.components
    .getChildren(mobileCompRef, true) // TODO bad performance
    .reduce((childToParentMap: CompIdToParentIdMap, compRef: CompRef) => {
      const parentId = ds.components.getContainer(compRef).id; // TODO bad performance
      return {
        ...childToParentMap,
        [compRef.id]: parentId,
      };
    }, {});
};

const collectStructureIdToParentMap = (
  ds: DocumentServicesObject,
  presetStructure: SerializedCompStructure,
  compIdToParentMap: CompIdToParentIdMap,
): void => {
  presetStructure.components?.forEach((structure) => {
    const id = structure.id as string;
    const compRef = getMobileCompRef(ds, id);
    if (compRef) {
      compIdToParentMap[id] = presetStructure.id!;
    }
    collectStructureIdToParentMap(ds, structure, compIdToParentMap);
  });
};

export const getMobileStructureToParentMap = (
  ds: DocumentServicesObject,
  mobileStructure: SerializedCompStructure,
): CompIdToParentIdMap => {
  const compIdToParentMap: CompIdToParentIdMap = {};
  collectStructureIdToParentMap(ds, mobileStructure, compIdToParentMap);
  return compIdToParentMap;
};

export const isHomepage = (
  generatedPage: GeneratedHomepage | GeneratedPage,
): generatedPage is GeneratedHomepage => {
  return (
    (<GeneratedHomepage>generatedPage).footer !== undefined &&
    (<GeneratedHomepage>generatedPage).header !== undefined
  );
};

const getParentsCompRefs = (
  ds: DocumentServicesObject,
  pageRef: CompRef,
  pagePartKey: PagePartKeys,
): CompRef[] => {
  if (pagePartKey === HEADER) {
    return [ds.siteSegments.getHeader()];
  }
  if (pagePartKey === FOOTER) {
    return [ds.siteSegments.getFooter()];
  }
  return ds.components.get.byType(COMP_TYPES.SECTION, pageRef);
};

export const wrapErrorReporter = (
  pageName: string,
  fullSiteStructure: GeneratedHomepage | GeneratedPage,
  isDebugMode: boolean,
): ReportError => {
  return (message: string, props: any = {}) => {
    ErrorReporter.captureException(message, {
      tags: {
        siteGenerationApiFlow: true,
      },
      extra: {
        ...props,
      },
    });
    if (!isDebugMode) {
      return;
    }
    window.console.log(`MOAJ: PAGE ${pageName} - ${message}`, {
      fullSiteStructure,
      ...props,
    });
  };
};

export const getUpdatedStructures = (
  ds: DocumentServicesObject,
  pageRef: CompRef,
  fullSiteStructure: GeneratedHomepage | GeneratedPage,
  pagePartKey: PagePartKeys,
  reportError: ReportError,
): SerializedCompStructure[][] => {
  const { structure, mobileStructure } = extractStructures(
    fullSiteStructure,
    pagePartKey,
  );
  const presetIdToSiteIdMap = getSiteIdToPresetIdMap(
    ds,
    pageRef,
    pagePartKey,
    structure,
    reportError,
  );
  return [structure, mobileStructure].map((structure) =>
    updatePresetIds(structure, presetIdToSiteIdMap),
  );
};

export const findCompInMobileStructureByCompId = (
  compStructure: SerializedCompStructure,
  compId: string,
): SerializedCompStructure | null => {
  if (!compId) return null;

  if (compStructure.id === compId) {
    return compStructure;
  }

  for (let currentCompStructure of compStructure.components || []) {
    const result = findCompInMobileStructureByCompId(
      currentCompStructure,
      compId,
    );
    if (result) return result;
  }

  return null;
};

const updateMobileMenuCompRef = (
  ds: DocumentServicesObject,
  mobileMenuCompsRefToUpdate: CompRef,
  mobileHeaderStructure: SerializedCompStructure,
) => {
  const compStructure = findCompInMobileStructureByCompId(
    mobileHeaderStructure,
    mobileMenuCompsRefToUpdate.id,
  );
  if (!compStructure) return;
  if (compStructure.design) {
    replaceDesign(ds, mobileMenuCompsRefToUpdate, compStructure.design);
  }
  replaceStyle(ds, mobileMenuCompsRefToUpdate, compStructure.style);
};

export const getMobileCompRefById = (
  ds: DocumentServicesObject,
  compId: string,
): CompRef | null => {
  return ds.components.get.byId(
    compId,
    undefined,
    ds.viewMode.VIEW_MODES.MOBILE,
  );
};

const geMobileMenuCompsRefToUpdate = (
  ds: DocumentServicesObject,
): CompRef[] => {
  return [
    `MENU_AS_CONTAINER_TOGGLE`,
    `MENU_AS_CONTAINER`,
    `MENU_AS_CONTAINER_EXPANDABLE_MENU`,
  ]
    .map((compId) => getMobileCompRefById(ds, compId))
    .filter(Boolean) as CompRef[];
};

export const adjustMobileMenuStyle = (
  ds: DocumentServicesObject,
  mobileHeaderStructure: SerializedCompStructure,
) => {
  const mobileMenuRefCompToUpdate = geMobileMenuCompsRefToUpdate(ds);
  mobileMenuRefCompToUpdate.forEach((compRef) => {
    updateMobileMenuCompRef(ds, compRef, mobileHeaderStructure);
  });
};
