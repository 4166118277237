import _ from 'lodash';
import { HttpClient, RequestOptions } from '@wix/http-client';
import { getInnerTextString } from '@wix/editor-content-injector';

import type {
  KitColorationPair,
  KitDefinition,
  KitExtendedDescription,
  KitExtendedDescriptionKey,
  KitInjectionOptions,
  KitVectorArtDefinition,
} from './types';
import type {
  ColorLayerData,
  CompLayout,
  SerializedCompStructure,
} from '@wix/document-services-types';
import {
  COLOR_ROLES,
  KitColorationPreset,
  KitGradientPreset,
  MAIN_BACKGROUND_COLOR,
} from './consts';

export type StructureByCompTypeEntry = {
  [compType: string]: SerializedCompStructure[];
};
export type StructureByIdEntry = { [compId: string]: SerializedCompStructure };

const ADD_PRESET_SERVERLESS_BASE_URL =
  'https://editor.wix.com/_serverless/editor-presets-bundle/';

const httpClient = new HttpClient();

const mapStructureByCompTypeRecursive = (
  presetStructure: SerializedCompStructure,
  structureByCompTypeEntry: StructureByCompTypeEntry,
): void => {
  if (!structureByCompTypeEntry[presetStructure.componentType]) {
    structureByCompTypeEntry[presetStructure.componentType] = [];
  }
  structureByCompTypeEntry[presetStructure.componentType].push(presetStructure);
  presetStructure.components?.forEach((childStructure) => {
    mapStructureByCompTypeRecursive(childStructure, structureByCompTypeEntry);
  });
};

export const mapStructureByCompType = (
  presetStructure: SerializedCompStructure,
): StructureByCompTypeEntry => {
  const structureByCompTypeEntry: StructureByCompTypeEntry = {};
  mapStructureByCompTypeRecursive(presetStructure, structureByCompTypeEntry);
  return structureByCompTypeEntry;
};

const mapStructureByIdRecursive = (
  presetStructure: SerializedCompStructure,
  structureByIdEntry: StructureByIdEntry,
): void => {
  structureByIdEntry[presetStructure.id!] = presetStructure;
  presetStructure.components?.forEach((childStructure) => {
    mapStructureByIdRecursive(childStructure, structureByIdEntry);
  });
};

export const mapStructureById = (
  presetStructure: SerializedCompStructure,
): StructureByIdEntry => {
  const structureByCompTypeEntry: StructureByIdEntry = {};
  mapStructureByIdRecursive(presetStructure, structureByCompTypeEntry);
  return structureByCompTypeEntry;
};

export const replaceVectorArt = (
  vectorArtStructure: SerializedCompStructure,
  kitVectorArtDefinition: KitVectorArtDefinition,
) => {
  const vectorArtLayout = vectorArtStructure.layout;
  if (!vectorArtLayout) {
    // TODO: implement for one doc
    console.error('could not replace vector art, no layout in structure');
    return;
  }
  vectorArtStructure.data = {
    ...vectorArtStructure.data,
    ..._.pick(kitVectorArtDefinition.data, ['svgId', 'title', 'type']),
  };
  vectorArtStructure.design = {
    ...vectorArtStructure.design,
    ..._.pick(kitVectorArtDefinition.design, ['shapeStyle', 'overrideColors']),
  };

  applyMediaItemCropData(vectorArtLayout, {
    height: kitVectorArtDefinition.dimensions.height,
    width: kitVectorArtDefinition.dimensions.width,
  });
};

const requestData = (url: string, requestOptions: RequestOptions = {}) => {
  return httpClient
    .get(url, requestOptions)
    .then(({ data }) => data)
    .catch((): unknown[] => {
      return [];
    });
};

const fetchKitDefinitionsFromServerless = (): Promise<KitDefinition[]> => {
  return requestData(`${ADD_PRESET_SERVERLESS_BASE_URL}stagingKits`);
};

const fetchKitDefinitionsFromProduction = (
  presetsBundleBaseUrl: string,
): Promise<KitDefinition[]> => {
  return requestData(`${presetsBundleBaseUrl}/kits.json`);
};

export const fetchKitDefinitions = async (
  isStagingMode: boolean,
  presetsBundleBaseUrl: string,
): Promise<KitDefinition[]> => {
  if (isStagingMode) {
    const stagedKits = await fetchKitDefinitionsFromServerless();
    if (stagedKits.length) {
      return stagedKits;
    }
  }
  return fetchKitDefinitionsFromProduction(presetsBundleBaseUrl);
};

const getTempReplacementColorName = (colorName: string) =>
  `%%__to_replace_${colorName.replace('color_', '')}__%%`;

const replaceColorationPairs = (
  compStructure: SerializedCompStructure,
  colorationPairs: KitColorationPair[],
): SerializedCompStructure => {
  let stringifiedCompStructure = JSON.stringify(compStructure);
  colorationPairs.forEach(([colorOrigin, colorReplace]) => {
    if (!colorOrigin || !colorReplace) {
      return;
    }
    const tempUniqueValueToApply = getTempReplacementColorName(colorReplace);
    const colorRegex = new RegExp(`\\b${colorOrigin}\\b`, 'g');
    stringifiedCompStructure = stringifiedCompStructure.replace(
      colorRegex,
      tempUniqueValueToApply,
    );
  });

  colorationPairs.forEach(([, colorReplace]) => {
    if (!colorReplace) {
      return;
    }
    const colorTempValueRegex = new RegExp(
      getTempReplacementColorName(colorReplace),
      'g',
    );
    stringifiedCompStructure = stringifiedCompStructure.replace(
      colorTempValueRegex,
      colorReplace,
    );
  });
  return JSON.parse(stringifiedCompStructure);
};

export const getFontNameFromText = (html: string): string => {
  return html.match(/class="(font_\d+)"/)?.[1] || '';
};

export const wrapInnerTextWithSpanInlineColor = (
  html: string,
  colorClass: string,
): string => {
  const innerText = getInnerTextString(html);
  return html.replace(
    innerText,
    `<span class="${colorClass}">${innerText}</span>`,
  );
};

export const getColorClassFromString = (str: string): string => {
  return str.match(/(color_\d+)/)?.[1] || '';
};

const injectInlineThemedTextColors = (
  { data }: SerializedCompStructure,
  colorationPairs: KitColorationPair[],
  kitFonts: KitDefinition['fonts'],
): void => {
  const hasInlineColor = !!getColorClassFromString(data.text);
  if (hasInlineColor) return;
  const fontName = getFontNameFromText(data.text);
  if (!fontName) return;
  const fontColor = kitFonts[fontName]?.color;
  if (!fontColor) return;
  const fontOriginColorClass = getColorClassFromString(fontColor);
  if (fontOriginColorClass) {
    const [, colorClassToApply] =
      colorationPairs.find(
        ([colorClass]) => colorClass === fontOriginColorClass,
      ) || [];
    if (colorClassToApply) {
      data.text = wrapInnerTextWithSpanInlineColor(
        data.text,
        colorClassToApply,
      );
    }
  }
};

const injectStructureInlineThemedTextColors = (
  rootStructure: SerializedCompStructure,
  colorationPairs: KitColorationPair[],
  kitFonts: KitDefinition['fonts'],
): void => {
  const structureTexts =
    mapStructureByCompType(rootStructure)[
      // TODO: use compTypes package when ready
      'wysiwyg.viewer.components.WRichText'
    ];
  structureTexts?.forEach((structure) => {
    injectInlineThemedTextColors(structure, colorationPairs, kitFonts);
  });
};

const disconnectThemedElements = (
  structure: SerializedCompStructure,
  themeStyles: KitDefinition['themeStyles'],
): void => {
  const structureByCompType = mapStructureByCompType(structure);
  // TODO: use compTypes package when ready
  const structures = [
    ...(structureByCompType['wysiwyg.viewer.components.SiteButton'] || []),
    ...(structureByCompType['wysiwyg.viewer.components.FiveGridLine'] || []),
    ...(structureByCompType['wysiwyg.viewer.components.menus.DropDownMenu'] ||
      []),
    ...(structureByCompType['mobile.core.components.Container'] || []),
  ];
  structures.forEach((structure) => {
    if (typeof structure.style === 'string' && themeStyles[structure.style]) {
      structure.style = {
        ...themeStyles[structure.style],
        styleType: 'custom',
      };
      delete structure.style.id;
    }
  });
};

export const replaceStructureColoration = (
  compStructure: SerializedCompStructure,
  colorationPairs: KitColorationPair[],
  { fonts, themeStyles }: KitDefinition,
): SerializedCompStructure => {
  disconnectThemedElements(compStructure, themeStyles);
  const replacedColorsStructure = replaceColorationPairs(
    compStructure,
    colorationPairs,
  );
  injectStructureInlineThemedTextColors(
    replacedColorsStructure,
    colorationPairs,
    fonts,
  );
  return replacedColorsStructure;
};

export const applyMediaItemCropData = (
  mediaLayout: CompLayout,
  newMediaDimensions: { height: number; width: number },
) => {
  const heightRatio = newMediaDimensions.height / newMediaDimensions.width;
  const isPortrait = heightRatio > 1;
  const isLandscape = heightRatio < 1;

  if (isPortrait) {
    const newWidth = Math.floor(mediaLayout.height / heightRatio);
    mediaLayout.x = Math.floor(
      mediaLayout.x + Math.abs(mediaLayout.width - newWidth) / 2,
    );
    mediaLayout.width = newWidth;
  }

  if (isLandscape) {
    const newHeight = Math.floor(mediaLayout.width * heightRatio);
    mediaLayout.y = Math.floor(
      mediaLayout.y + Math.abs(mediaLayout.height - newHeight) / 2,
    );
    mediaLayout.height = newHeight;
  }
};

export const getDefaultKitInjectionOptions = (
  kitDefinition: KitDefinition,
): KitInjectionOptions => {
  if (kitDefinition.isGradientKit) {
    return {
      gradientPreset: KitGradientPreset.MixHarmony,
    };
  }
  return {
    colorationPreset: KitColorationPreset.Plain,
  };
};

const createColorText = (type: string, color: string): string => {
  return `${type} color ${color}.`;
};

const getColorAsString = (
  kitDefinition: KitDefinition,
  kitDefinitionColoration: KitColorationPair[],
): string => {
  const color =
    kitDefinition.colors[
      kitDefinitionColoration.find(
        ([colorName]: KitColorationPair) => colorName === MAIN_BACKGROUND_COLOR,
      )?.[1] as string
    ];

  return color ? color : kitDefinition.colors[MAIN_BACKGROUND_COLOR];
};

const getReadableKitDescription = (description: string): string => {
  return description ? description + '.' : '';
};

const createDescription = (
  kitDefinition: KitDefinition,
  preset: KitColorationPreset,
  primaryColor: string,
  secondaryColor: string,
  additionalColor: string,
): KitExtendedDescription => {
  const colors = [primaryColor, secondaryColor, additionalColor];

  const colorsDescription = COLOR_ROLES.map(
    (colorRole, index) =>
      colors[index] && createColorText(colorRole, colors[index]),
  )
    .filter(Boolean)
    .join(' ');

  const kitName = kitDefinition.title;
  const kitDescription = getReadableKitDescription(kitDefinition.description);

  const description = kitDescription
    ? `${kitDescription} ${colorsDescription}`
    : colorsDescription;

  return {
    description,
    primaryColor,
    ...(secondaryColor && { secondaryColor }),
    ...(additionalColor && { additionalColor }),
    kitName,
    preset,
    embedding: [],
  };
};

const getColorationPresetDescription = (
  kitDefinition: KitDefinition,
  preset: KitColorationPreset,
): KitExtendedDescription => {
  const primaryColor = kitDefinition.colors[MAIN_BACKGROUND_COLOR];

  const secondaryColor = getColorAsString(
    kitDefinition,
    kitDefinition.coloration.Secondary,
  );

  const additionalColor = getColorAsString(
    kitDefinition,
    kitDefinition.coloration.Extra,
  );

  switch (preset) {
    case KitColorationPreset.Plain:
      return createDescription(kitDefinition, preset, primaryColor, '', '');

    case KitColorationPreset.MixSingle:
      return createDescription(
        kitDefinition,
        preset,
        primaryColor,
        secondaryColor,
        additionalColor,
      );

    case KitColorationPreset.MixDouble:
      return createDescription(
        kitDefinition,
        preset,
        additionalColor,
        secondaryColor,
        primaryColor,
      );
  }
};

export const getKitExtendedDescriptionsForColoration = (
  kitDefinition: KitDefinition,
): Record<KitExtendedDescriptionKey, KitExtendedDescription> => {
  return {
    [`${kitDefinition.title}_${KitColorationPreset.Plain}`]:
      getColorationPresetDescription(kitDefinition, KitColorationPreset.Plain),
    [`${kitDefinition.title}_${KitColorationPreset.MixSingle}`]:
      getColorationPresetDescription(
        kitDefinition,
        KitColorationPreset.MixSingle,
      ),
    [`${kitDefinition.title}_${KitColorationPreset.MixDouble}`]:
      getColorationPresetDescription(
        kitDefinition,
        KitColorationPreset.MixDouble,
      ),
  };
};

const getGradientColors = (
  kitDefiinition: KitDefinition,
  kitDefinitionGradient: ColorLayerData | undefined,
): string[] => {
  const gradientColors = new Set<string>();

  if (kitDefinitionGradient) {
    const { type } = kitDefinitionGradient.fill;
    switch (type) {
      case 'GradientLinear':
        kitDefinitionGradient.fill['colorStops'].map((colorStop) => {
          if (colorStop.color)
            if (kitDefiinition.colors[colorStop.color])
              gradientColors.add(kitDefiinition.colors[colorStop.color]);
            else gradientColors.add(colorStop.color);
        });

        break;

      case 'GradientMesh':
        kitDefinitionGradient.fill['gradients'].map((gradient) => {
          gradient['colorStops'].map((colorStop) => {
            if (colorStop.color)
              if (kitDefiinition.colors[colorStop.color])
                gradientColors.add(kitDefiinition.colors[colorStop.color]);
              else gradientColors.add(colorStop.color);
          });
        });
        break;

      default:
        break;
    }
  }

  return Array.from(gradientColors);
};

const getGradientColorsFromKit = (
  kitDefinition: KitDefinition,
  gradientPreset: KitGradientPreset | undefined,
): string[] => {
  switch (gradientPreset) {
    case KitGradientPreset.Side:
      return getGradientColors(kitDefinition, kitDefinition.gradients?.side[0]);

    case KitGradientPreset.MixHarmony:
      return Array.from(
        new Set([
          ...getGradientColors(
            kitDefinition,
            kitDefinition.gradients?.bottom[0],
          ),
          ...getGradientColors(kitDefinition, kitDefinition.gradients?.top[0]),
        ]),
      );

    case KitGradientPreset.MixSingle:
    case KitGradientPreset.Welcome:
      return getGradientColors(
        kitDefinition,
        kitDefinition.gradients?.freestyle[0],
      );

    default:
      return [];
  }
};

const getGradientPresetDescription = (
  kitDefinition: KitDefinition,
  preset: KitGradientPreset,
): KitExtendedDescription => {
  const kitName = kitDefinition.title;

  const gradientColors = getGradientColorsFromKit(kitDefinition, preset);

  const colorsDescription = COLOR_ROLES.map(
    (colorRole, index) =>
      gradientColors[index] &&
      createColorText(colorRole, gradientColors[index]),
  )
    .filter(Boolean)
    .join(' ');

  const kitDescription = getReadableKitDescription(kitDefinition.description);
  const description = kitDescription
    ? `${kitDescription} ${colorsDescription}`
    : colorsDescription;

  const [primaryColor, secondaryColor, additionalColor] = gradientColors;

  return {
    description,
    primaryColor,
    ...(secondaryColor && { secondaryColor }),
    ...(additionalColor && { additionalColor }),
    kitName,
    preset,
    embedding: [],
  };
};

export const getKitExtendedDescriptionsForGradient = (
  kitDefinition: KitDefinition,
): Record<KitExtendedDescriptionKey, KitExtendedDescription> => {
  return {
    [`${kitDefinition.title}_${KitGradientPreset.Side}`]:
      getGradientPresetDescription(kitDefinition, KitGradientPreset.Side),
    [`${kitDefinition.title}_${KitGradientPreset.MixHarmony}`]:
      getGradientPresetDescription(kitDefinition, KitGradientPreset.MixHarmony),
    [`${kitDefinition.title}_${KitGradientPreset.MixSingle}`]:
      getGradientPresetDescription(kitDefinition, KitGradientPreset.MixSingle),
    [`${kitDefinition.title}_${KitGradientPreset.Welcome}`]:
      getGradientPresetDescription(kitDefinition, KitGradientPreset.Welcome),
  };
};

export const getKitExtendedDescriptions = (
  kitDefinition: KitDefinition,
): Record<KitExtendedDescriptionKey, KitExtendedDescription> => {
  if (kitDefinition.isGradientKit) {
    return getKitExtendedDescriptionsForGradient(kitDefinition);
  }
  return getKitExtendedDescriptionsForColoration(kitDefinition);
};
