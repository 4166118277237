import {
  applyPropertiesFromStructure,
  collectFlatCompList,
  getMobileCompRef,
  getMobileStructureToParentMap,
  getPagePartsKeys,
  getRenderedToParentMap,
  getUpdatedStructures,
  hideNeededComps,
  wrapErrorReporter,
  updateLayoutIfNeeded,
  isHomepage,
  adjustMobileMenuStyle,
} from './mobileLayoutAdjustmentUtils';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { PagePartKeys } from '../types';
import { SECTIONS } from './mobileLayoutAdjustmentConsts';

import type {
  CompRef,
  DocumentServicesObject,
  SerializedCompStructure,
} from '@wix/document-services-types';
import type { GeneratedHomepage, GeneratedPage } from '../types';
import type { ReportError } from '@wix/editor-content-injector';

const applyLayoutFromStructure = (
  ds: DocumentServicesObject,
  flatCompListMobile: SerializedCompStructure[],
  reportError: ReportError,
) => {
  for (const { layout, id } of flatCompListMobile) {
    if (!id || !layout) {
      continue;
    }
    const mobileCompRef = getMobileCompRef(ds, id);
    if (!mobileCompRef) {
      continue;
    }
    updateLayoutIfNeeded(ds, mobileCompRef, layout, reportError);
  }
};

const reparentMobileComps = (
  ds: DocumentServicesObject,
  mobileStructures: SerializedCompStructure[],
) => {
  for (const mobileStructure of mobileStructures) {
    const mobileCompId = mobileStructure.id as string;
    const mobileCompRef = getMobileCompRef(ds, mobileCompId);
    if (!mobileCompRef || !ds.components.is.exist(mobileCompRef)) {
      continue;
    }
    const renderedToParentMap = getRenderedToParentMap(ds, mobileCompRef);
    const structureToParentMap = getMobileStructureToParentMap(
      ds,
      mobileStructure,
    );
    for (const [compId, structureParentId] of Object.entries(
      structureToParentMap,
    )) {
      const parentsMismatch = renderedToParentMap[compId] !== structureParentId;
      const compRef = getMobileCompRef(ds, compId);
      const containerCompRef = getMobileCompRef(ds, structureParentId);
      if (
        compRef &&
        parentsMismatch &&
        containerCompRef &&
        ds.components.is.containable(compRef, containerCompRef)
      ) {
        ds.components.setContainer(compRef, containerCompRef);
      }
    }
  }
};

const adjustSectionsVerticalSizeAndPosition = (
  ds: DocumentServicesObject,
  pageRef: CompRef,
  pagePartKey: PagePartKeys,
  reportError: ReportError,
) => {
  const mobilePageCompRef = getMobileCompRef(ds, pageRef.id);
  if (pagePartKey !== SECTIONS || !mobilePageCompRef) {
    return;
  }
  let lastYPosition = 0;
  const mainChildren = ds.components.getChildren(mobilePageCompRef);
  for (const pageChild of mainChildren) {
    const layout = ds.components.layout.get(pageChild);
    if (!layout?.height) {
      continue;
    }
    const { height } = layout;
    updateLayoutIfNeeded(ds, pageChild, { y: lastYPosition }, reportError);
    lastYPosition += height;
  }
  updateLayoutIfNeeded(
    ds,
    mobilePageCompRef,
    { height: lastYPosition },
    reportError,
  );
};

/**
 * This is some weird bug (that maybe caused by hideNeededComps)
 * that moves the "back to top button" to middle of page, so we need to reset it
 */
const adjustBackToTopButton = (ds: DocumentServicesObject) => {
  const backToTopButtonRef: CompRef = {
    id: 'BACK_TO_TOP_BUTTON',
    type: 'MOBILE',
  };
  const exists = ds.components.is.exist(backToTopButtonRef);
  if (!exists) {
    return;
  }
  ds.components.layout.updateDock(backToTopButtonRef, {
    bottom: {
      px: 20,
    },
  });
};

const adjustMobileLayout = (
  ds: DocumentServicesObject,
  pageRef: CompRef,
  pagePartKey: PagePartKeys,
  structures: SerializedCompStructure[],
  mobileStructures: SerializedCompStructure[],
  reportError: ReportError,
) => {
  const flatCompListDesktop = collectFlatCompList(structures);
  const flatCompListMobile = collectFlatCompList(mobileStructures);

  reparentMobileComps(ds, mobileStructures);
  hideNeededComps(ds, flatCompListDesktop, flatCompListMobile);
  applyLayoutFromStructure(ds, flatCompListMobile, reportError);
  applyPropertiesFromStructure(ds, flatCompListMobile);
  adjustSectionsVerticalSizeAndPosition(ds, pageRef, pagePartKey, reportError);
  adjustBackToTopButton(ds);
};

export const runPageMobileAdjustments = async (
  ds: DocumentServicesObject,
  pageRef: CompRef,
  fullSiteStructure: GeneratedHomepage | GeneratedPage,
  isDebugMode: boolean = false,
): Promise<void> => {
  ErrorReporter.breadcrumb('runPageMobileAdjustments start', {
    pageName: fullSiteStructure.name,
  });
  const reportError = wrapErrorReporter(
    fullSiteStructure.name,
    fullSiteStructure,
    isDebugMode,
  );

  if (isHomepage(fullSiteStructure) && fullSiteStructure.header) {
    adjustMobileMenuStyle(ds, fullSiteStructure.header.mobileStructure);
  }

  const pagePartsKeys = getPagePartsKeys(fullSiteStructure);
  for (const pagePartKey of pagePartsKeys) {
    const [updatedStructure, updatedMobileStructure] = getUpdatedStructures(
      ds,
      pageRef,
      fullSiteStructure,
      pagePartKey,
      reportError,
    );

    await ds.transactions.run(async () => {
      adjustMobileLayout(
        ds,
        pageRef,
        pagePartKey,
        updatedStructure,
        updatedMobileStructure,
        reportError,
      );
    });
  }
};
