import _ from 'lodash';
import {
  fetchLayoutFamilies,
  fetchPresetStructures,
  getFooters,
  getHeaders,
  getSectionsPresets,
} from './utils/fetchDataUtils';
import {
  adjustAndReplaceHeader,
  canSectionPresetBeAdded,
  createPageStructure,
  fixPresetStructureApplicationIds,
  getAppDefIdsNotToPersonalize,
  getHomepagePresetsLocked,
  getKitSiteStructure,
  getSectionsStructures,
  getSiteGeneratorPlatformOrigin,
  getSiteTheme,
  getUniquePageName,
  handleCustomAppPageAsHomepage,
  isAppInstalled,
  isExist,
  isGeneratedHomepageStructure,
  isHomepageStructure,
  navigateToPage,
  removePreviousPage,
  replaceFooter,
  replaceSectionsAnchorNames,
  replaceStoresIfNeeded,
  retrieveAppDefIdsFromStructure,
  setPageTextsSource,
  setSiteTheme,
  setUsesNewAnimations,
  syncAndResetMobileLayout,
  getMetaSiteInstance,
  addSectionsToMenuIfNeeded,
} from './utils/siteGeneratorUtils';
import {
  filterFooters,
  filterHeaders,
  getFooterStructure,
  getHeaderStructure,
  adjustLogoLayout,
} from './utils/headerAndFooterUtils';
import { getFullKitInjectionOptions } from './utils/injectKitUtils';
import {
  ContentKind,
  ContentProvider,
  getContentCategoryByCeType,
  PageData,
  Providers,
  languageCodeToLanguageName,
} from '@wix/editor-content-provider';
import {
  getHomepagePresets,
  isExceptionToStyleRules,
} from './utils/styleRulesUtils';
import type { KitDefinition, KitInjectionOptions } from '@wix/editor-kits';
import { fetchKitDefinitions, injectKitIntoStructure } from '@wix/editor-kits';
import { ContentInjector } from '@wix/editor-content-injector';
import {
  ALLOWED_HEADLESS_INSTALL_APP_DEF_IDS,
  ALLOWED_SILENT_INSTALLATION_APPS,
  DEFAULT_LANGUAGE_CODE,
} from './consts';
import {
  dsDebugTrace,
  logger,
  setFedopsInteractions,
  verifyInitContent,
} from './utils/decorators';
import { getBlankPageStructure } from './utils/getBlankPageStructure';
import { logTextChanges } from './utils/debugUtils';
import { a11yUpgradeTitlesInPage } from './utils/a11yUtils';
import { runPageMobileAdjustments } from './mobileLayoutAdjustment/mobileLayoutAdjustment';
import { removeEmptyTransparentBoxes } from './utils/removeEmptyTransparentBoxes';
import { reorderSitePages } from './utils/pagesUtils';
import { MediaItem, Volume } from '@wix/adi-content-api';
import { ErrorReporter } from '@wix/editor-error-reporter';
import type { AddedPageWithRef } from './types';
import type {
  IndustryProfileData,
  PageSectionContent,
  UserData,
  BusinessTypeInfo,
} from '@wix/editor-content-provider';
import type { CeType } from '@wix/adi-core-types';
import type {
  ColorPalette,
  CompRef,
  DocumentServicesObject,
  SerializedCompStructure,
  SerializedPageStructure,
} from '@wix/document-services-types';
import type {
  BuildPageOptions,
  FedopsInteraction,
  FooterPresetDefinition,
  FooterPresetStructure,
  FullHomepageStructure,
  GeneratedHomepage,
  GeneratedPage,
  HeaderPresetDefinition,
  HeaderPresetStructure,
  LayoutFamilyDefinition,
  PageStructure,
  PresetStructure,
  SectionPresetDefinition,
  SectionPresetStructure,
  SiteGeneratorOptions,
} from './types';
import type {
  AppInstallOrigin,
  AppInstallOption,
} from '@wix/editor-platform-host-integration-apis';
import { HomepagePresets, LockedPresetIds } from './types';
import { getColorsFromImageAndCurrentPalette } from '@wix/editor-theme-utils';

// TODO eslint block the option to use arrow function as class methods

export class SiteGenerator {
  private userData: UserData;
  private structureId: string;
  private industryId: string;
  private useStagingMode: boolean;
  private usedMediaGlobal: MediaItem[] = [];
  private appDefIds: string[];
  private isColorFromLogoNeeded: boolean = false;
  private currentLogoPromptPalette: Partial<ColorPalette>[];
  private headlessInstallApps: (
    appDefIds: string[],
    appsInstallOptions: Record<string, AppInstallOption>,
    platformOrigin: AppInstallOrigin,
  ) => Promise<void>;
  private readonly isDebugMode: boolean;
  private readonly languageCode: string = DEFAULT_LANGUAGE_CODE;
  private readonly presetsBundleBaseUrl: string;
  private readonly staticMediaUrl: string;
  private readonly shouldUseSgInstallOrigin: boolean;
  private readonly layoutFamiliesPromise: Promise<LayoutFamilyDefinition[]>;
  private readonly kitDefinitionsPromise: Promise<KitDefinition[]>;
  private readonly contentProvider: ContentProvider;
  private readonly contentInjector: ContentInjector;
  private readonly interactionStarted: FedopsInteraction;
  private readonly interactionEnded: FedopsInteraction;
  private readonly translate: (key: string) => string;

  constructor({
    languageCode,
    presetsBundleBaseUrl,
    staticMediaUrl,
    interactionStarted = _.noop,
    interactionEnded = _.noop,
    isStagingMode = false,
    isDebugMode = false,
    userData,
    translate,
    contentVersion,
    shouldUseSgInstallOrigin,
  }: SiteGeneratorOptions) {
    setFedopsInteractions({
      interactionStarted,
      interactionEnded,
    });
    this.interactionStarted = interactionStarted;
    this.interactionEnded = interactionEnded;
    this.shouldUseSgInstallOrigin = shouldUseSgInstallOrigin;
    this.userData = userData;
    this.isDebugMode = isDebugMode;
    this.useStagingMode = isStagingMode;
    this.languageCode = languageCode || DEFAULT_LANGUAGE_CODE;
    this.presetsBundleBaseUrl = presetsBundleBaseUrl;
    this.staticMediaUrl = staticMediaUrl;
    this.contentProvider = new ContentProvider({
      provider: Providers.CAAS,
      origin: 'siteGenerator',
      locale: this.languageCode,
      contentVersion,
    });
    this.layoutFamiliesPromise = fetchLayoutFamilies(
      isStagingMode,
      presetsBundleBaseUrl,
    );
    this.kitDefinitionsPromise = fetchKitDefinitions(
      isStagingMode,
      presetsBundleBaseUrl,
    );
    this.contentInjector = new ContentInjector();
    this.appDefIds = [];
    this.translate = translate;
  }

  setIsColorFromLogoNeeded(isColorFromLogoNeeded: boolean) {
    this.isColorFromLogoNeeded = isColorFromLogoNeeded;
  }

  getIsColorFromLogoNeeded() {
    return this.isColorFromLogoNeeded;
  }

  private resetUsedMedia() {
    this.usedMediaGlobal = [];
  }

  @logger('sg_init_content')
  async initContent(
    industryId: string,
    structureId: string,
    businessTypeName: string,
  ): Promise<void> {
    this.industryId = industryId;
    this.structureId = structureId;
    await this.contentProvider.initContent(industryId, structureId);
    const profiles = await this.getIndustryProfiles();
    if (profiles.length) {
      this.setIndustryProfile(_.sample(profiles)!.id);
    }
    this.setUserData({ industryId, structureId, businessTypeName });
  }

  @verifyInitContent
  async getKits(): Promise<KitDefinition[]> {
    const kitDefinitions = await this.kitDefinitionsPromise;
    const contentSuggestedKits = await this.contentProvider.getSuggestedKits();
    const suggestedKitsIdsToVolumeMap = new Map();
    contentSuggestedKits.forEach(({ id, volume }) => {
      suggestedKitsIdsToVolumeMap.set(id, volume);
    });
    return kitDefinitions.map((kitDefinition) => ({
      ...kitDefinition,
      volume:
        suggestedKitsIdsToVolumeMap.get(kitDefinition.title) || Volume.Optional,
    }));
  }

  private getLogoColorPalette() {
    if (
      this.isColorFromLogoNeeded &&
      this.userData.logo?.uri &&
      this.currentLogoPromptPalette
    ) {
      return this.currentLogoPromptPalette[0];
    }
  }

  getSiteTheme(
    ds: DocumentServicesObject,
    kitDefinition: KitDefinition,
    layoutFamilyDefinition: LayoutFamilyDefinition,
  ) {
    const logoColorPalette = this.getLogoColorPalette();
    return getSiteTheme(
      ds,
      kitDefinition,
      layoutFamilyDefinition,
      logoColorPalette,
    );
  }

  @verifyInitContent
  async getLayoutFamilies(): Promise<LayoutFamilyDefinition[]> {
    const layoutFamilies = await this.layoutFamiliesPromise;
    const layoutFamiliesBlacklist = new Set(
      await this.contentProvider.getLayoutFamilyBlacklist(),
    );
    return layoutFamilies.map((layoutFamily) => ({
      ...layoutFamily,
      volume: layoutFamiliesBlacklist.has(layoutFamily.title)
        ? Volume.Optional
        : Volume.BuiltIn,
    }));
  }

  @verifyInitContent
  async getBlacklistedLayoutFamilies() {
    const layoutFamilies = await this.layoutFamiliesPromise;
    const layoutFamiliesBlacklist = new Set(
      await this.contentProvider.getLayoutFamilyBlacklist(),
    );
    return layoutFamilies.filter(({ title }) =>
      layoutFamiliesBlacklist.has(title),
    );
  }

  setUserData(userData: Partial<UserData>) {
    this.userData = { ...this.userData, ...userData };
    this.contentProvider.setUserData(this.userData);
    this.contentInjector.setUserData(this.userData);
  }

  getBusinessTypeInfo(term: string): Promise<BusinessTypeInfo | null> {
    return this.contentProvider.getBusinessTypeByTerm(term);
  }

  getUserData(): UserData {
    return this.userData;
  }

  setHeadlessInstallAppsFn(
    headlessInstallApps: (
      appDefIds: string[],
      appsInstallOptions: Record<string, AppInstallOption>,
      platformOrigin: AppInstallOrigin,
    ) => Promise<void>,
  ) {
    this.headlessInstallApps = headlessInstallApps;
  }

  toggleStagingMode(useStagingMode: boolean) {
    this.useStagingMode = useStagingMode;
  }

  private async getPageSections(
    layoutFamilyName: string,
    sectionContents: PageSectionContent[],
  ): Promise<SectionPresetDefinition[][]> {
    return Promise.all(
      sectionContents.map(async (sectionContent) => {
        return getSectionsPresets(
          sectionContent.contentCategory,
          layoutFamilyName,
          this.useStagingMode,
          this.presetsBundleBaseUrl,
        );
      }),
    );
  }

  @logger('sg_get_headers')
  private async getHeaders(
    layoutFamilyName: string,
    lockedPresetIds?: LockedPresetIds,
  ): Promise<HeaderPresetDefinition[]> {
    return getHeaders(
      layoutFamilyName,
      this.useStagingMode,
      this.presetsBundleBaseUrl,
    ).then(async (headers) => {
      const hasLogo = !!this.userData.logo?.uri;
      const filteredHeaders = filterHeaders(
        headers,
        hasLogo,
        this.getChosenApps(),
        this.shouldUseSgInstallOrigin,
      );
      if (!filteredHeaders.length) {
        return headers;
      }
      const lockedHeader = filteredHeaders.find(
        ({ _id }) => lockedPresetIds?.header === _id,
      );
      if (lockedHeader) {
        return [lockedHeader];
      }
      return filteredHeaders;
    });
  }

  @logger('sg_get_footers')
  private async getFooters(
    layoutFamilyName: string,
    lockedPresetIds?: LockedPresetIds,
  ): Promise<FooterPresetDefinition[]> {
    return getFooters(
      layoutFamilyName,
      this.useStagingMode,
      this.presetsBundleBaseUrl,
    ).then(async (footers) => {
      const hasLogo = !!this.userData.logo?.uri;
      const filteredFooters = filterFooters(footers, hasLogo, this.appDefIds);
      if (!filteredFooters.length) {
        return footers;
      }
      const lockedFooter = filteredFooters.find(
        ({ _id }) => lockedPresetIds?.footer === _id,
      );
      if (lockedFooter) {
        return [lockedFooter];
      }
      return filteredFooters;
    });
  }

  private getHomepagePresets(
    homepageData: PageData,
    fetchedHeaders: HeaderPresetDefinition[],
    fetchedSections: SectionPresetDefinition[][],
    fetchedFooters: FooterPresetDefinition[],
    lockedPresetsIds?: LockedPresetIds,
  ): {
    homepagePresets: HomepagePresets;
    updatedHomepageData: PageData;
  } {
    if (lockedPresetsIds) {
      const { headerPreset, sectionsPresets, footerPreset } =
        getHomepagePresetsLocked(
          fetchedHeaders,
          fetchedSections,
          fetchedFooters,
          lockedPresetsIds,
        );
      const updatedHomepageData = {
        ...homepageData,
        sections: homepageData.sections
          .filter((_, index) => fetchedSections[index].length)
          .filter((_, index) => sectionsPresets[index]),
      };
      return {
        homepagePresets: {
          headerPreset,
          footerPreset,
          sectionsPresets: sectionsPresets.filter(
            Boolean,
          ) as SectionPresetDefinition[],
        },
        updatedHomepageData,
      };
    }
    const homepagePresets = getHomepagePresets(
      fetchedHeaders,
      fetchedSections,
      fetchedFooters,
      isExceptionToStyleRules(this.structureId, this.industryId),
    );
    const updatedHomepageData = {
      ...homepageData,
      sections: homepageData.sections.filter(
        (_, index) => fetchedSections[index].length,
      ),
    };
    return {
      homepagePresets,
      updatedHomepageData,
    };
  }

  private updateLogoColorsPaletteMap = async (
    metaSiteInstanceId: string,
    kitDefinition?: KitDefinition,
  ) => {
    if (
      !this.isColorFromLogoNeeded ||
      !this.userData.logo?.uri ||
      !kitDefinition
    ) {
      return;
    }
    try {
      this.interactionStarted('sg_get_color_from_logo');
      const { fullPalettes } = await getColorsFromImageAndCurrentPalette({
        imagePath: `media/${this.userData.logo.uri}`,
        currentPalette: kitDefinition.colors,
        metaSiteInstanceId,
      });
      this.interactionEnded('sg_get_color_from_logo');
      this.currentLogoPromptPalette = fullPalettes;
    } catch (error) {
      this.isColorFromLogoNeeded = false;
      console.log(`Fetch colors from image and color palette failed: ${error}`);
    }
  };

  @logger('sg_build_full_homepage_structure')
  @verifyInitContent
  async buildFullHomepageStructure(
    ds: DocumentServicesObject,
    layoutFamilyName: string,
    homepageData: PageData,
    { injectCaasText = true, injectAiText = true }: BuildPageOptions,
    lockedPresetsIds?: LockedPresetIds,
    kitDefnition?: KitDefinition,
  ): Promise<GeneratedHomepage> {
    const updateLogoColorsPaletteMapPromise = this.updateLogoColorsPaletteMap(
      getMetaSiteInstance(ds),
      kitDefnition,
    );

    ErrorReporter.breadcrumb('buildFullHomepageStructure', {
      layoutFamilyName,
      sections: homepageData.sections.map(({ contentCategory }) => {
        contentCategory;
      }),
      lockedPresetsIds,
    });
    const [fetchedHeaders, fetchedSections, fetchedFooters] = await Promise.all(
      [
        this.getHeaders(layoutFamilyName, lockedPresetsIds),
        this.getPageSections(layoutFamilyName, homepageData.sections),
        this.getFooters(layoutFamilyName, lockedPresetsIds),
      ],
    );

    const { homepagePresets, updatedHomepageData } = this.getHomepagePresets(
      homepageData,
      fetchedHeaders,
      fetchedSections,
      fetchedFooters,
      lockedPresetsIds,
    );
    const { headerPreset, sectionsPresets, footerPreset } = homepagePresets;

    ErrorReporter.breadcrumb(
      'buildFullHomepageStructure - chosen section presets',
      {
        sectionsPresets: sectionsPresets.map(
          ({ contentCategory }) => contentCategory,
        ),
        updatedHomepageDataSections: updatedHomepageData.sections.map(
          ({ contentCategory }) => contentCategory,
        ),
      },
    );
    const [header, sections, footer] = await Promise.all([
      getHeaderStructure(
        headerPreset,
        this.appDefIds,
        updatedHomepageData.sections,
      ),
      getSectionsStructures(sectionsPresets, updatedHomepageData.sections),
      getFooterStructure(footerPreset, this.userData.socialLinks),
    ]);

    replaceSectionsAnchorNames(sections, this.translate);

    const [homepageStructure] = await Promise.all([
      this.injectSiteStructureContent(
        ds,
        { injectCaasText, injectAiText },
        {
          header,
          sections,
          footer,
        },
      ),
      updateLogoColorsPaletteMapPromise,
    ]);
    return {
      ...homepageStructure,
      name: updatedHomepageData.name,
    };
  }

  async getHomepageData(ds: DocumentServicesObject): Promise<PageData> {
    if (!ds) {
      throw new Error('no ds provided in getHomepageData');
    }
    const appDefIdsNotToPersonalize = getAppDefIdsNotToPersonalize(ds);
    return this.contentProvider.getHomepageData(
      this.appDefIds,
      appDefIdsNotToPersonalize,
    );
  }

  async getAdditionalPagesData(): Promise<PageData[]> {
    return this.contentProvider.getAdditionalPagesData();
  }

  @logger('sg_build_page_structure')
  @verifyInitContent
  async buildPageStructure(
    ds: DocumentServicesObject,
    layoutFamilyName: string,
    pageData: PageData,
    { injectCaasText = true, injectAiText = true }: BuildPageOptions,
    lockedPresetsIds?: string[],
  ): Promise<GeneratedPage> {
    const sectionsPresets = await this.getPageSections(
      layoutFamilyName,
      pageData.sections,
    ).then(
      (presets) =>
        presets
          .map((sectionPresetList) => {
            if (lockedPresetsIds) {
              return sectionPresetList.find((section) =>
                lockedPresetsIds.find((presetId) => section._id === presetId),
              );
            }
            return _.sample(sectionPresetList);
          })
          .filter((section) => !!section) as SectionPresetDefinition[],
    );
    const sections = await getSectionsStructures(
      sectionsPresets,
      pageData.sections,
    );
    a11yUpgradeTitlesInPage(sections);
    replaceSectionsAnchorNames(sections, this.translate);
    const pageStructure = await this.injectSiteStructureContent(
      ds,
      { injectCaasText, injectAiText },
      { sections },
    );
    return {
      ...pageStructure,
      name: pageData.name,
    };
  }

  @logger('sg_inject_site_structure_content')
  @verifyInitContent
  private async injectSiteStructureContent<
    T extends PageStructure | FullHomepageStructure,
  >(
    ds: DocumentServicesObject,
    { injectCaasText, injectAiText }: BuildPageOptions,
    pageStructure: T,
  ): Promise<T> {
    const getStructure = async <
      K extends
        | SectionPresetStructure
        | HeaderPresetStructure
        | FooterPresetStructure,
    >(
      presetStructureContent: K,
    ): Promise<K> => {
      const { structure } = presetStructureContent;
      const contentType =
        (presetStructureContent as PageSectionContent).ceType || null;
      this.interactionStarted('sg_content_provider_get_content');
      const content = await this.contentProvider.getContent(
        {
          contentType,
          industryId: this.industryId,
          structureId: this.structureId,
        },
        structure,
        injectCaasText ? ContentKind.ALL : ContentKind.MEDIA,
      );
      this.interactionEnded('sg_content_provider_get_content');
      this.interactionStarted(
        injectCaasText
          ? 'sg_content_injector_inject_preset_structure_full_content'
          : 'sg_content_injector_inject_preset_structure_only_media',
      );
      const injectedStructure = this.contentInjector.injectPresetStructure(
        structure,
        content,
        this.usedMediaGlobal,
        this.isDebugMode,
      );
      this.interactionEnded(
        injectCaasText
          ? 'sg_content_injector_inject_preset_structure_full_content'
          : 'sg_content_injector_inject_preset_structure_only_media',
      );

      return {
        ...presetStructureContent,
        structure: injectedStructure,
      };
    };

    this.resetUsedMedia();

    const sections = await Promise.all(
      pageStructure.sections.map(getStructure),
    );

    if (injectCaasText && this.isDebugMode) {
      logTextChanges(
        'CAAS',
        pageStructure.sections.map(({ structure }) => structure),
        sections.map(({ structure }) => structure),
      );
    }

    if (injectAiText) {
      this.interactionStarted('sg_ai_inject_content');
      const sectionsStructure = _.cloneDeep(
        sections.map(({ structure }) => structure),
      );
      const fullPageStructure = getBlankPageStructure(
        'pageUriSEO',
        sectionsStructure,
      );

      this.interactionStarted('sg_ds_ai_inject_content');
      const aiInjectedSections = (await new Promise<SerializedPageStructure>(
        (resolve) => {
          ds.ai.content.getContentByPageStructure(
            this.userData?.businessTypeName ?? '', // TODO handle optional
            this.userData?.businessName ?? '',
            this.userData?.description ?? '',
            fullPageStructure,
            (injectedPageStructure) => {
              this.interactionEnded('sg_ds_ai_inject_content');
              resolve(injectedPageStructure);
            },
            (e) => {
              ErrorReporter.captureException(e, {
                tags: {
                  siteGenerationInjectContent: true,
                },
                extra: {
                  ...this.userData,
                  sectionIds: sections.map(({ id }) => id),
                },
              });
              resolve(fullPageStructure);
            },
            languageCodeToLanguageName[this.languageCode],
          );
        },
      )) as SerializedCompStructure;

      if (this.isDebugMode) {
        logTextChanges(
          'AI',
          sections.map(({ structure }) => structure),
          aiInjectedSections.components!,
        );
      }

      sections.forEach((section, index) => {
        if (aiInjectedSections?.components?.[index]) {
          section.structure = aiInjectedSections.components[index];
        }
      });
      this.interactionEnded('sg_ai_inject_content');
    }

    if (isHomepageStructure(pageStructure)) {
      const header = pageStructure.header
        ? await getStructure(pageStructure.header)
        : null;
      const footer = pageStructure.footer
        ? await getStructure(pageStructure.footer)
        : null;
      adjustLogoLayout(header);
      adjustLogoLayout(footer);
      return {
        header,
        sections,
        footer,
      } as T;
    }

    return {
      sections,
    } as T;
  }

  @logger('sg_verify_widgets_installed')
  private async verifyWidgetsInstalled(
    ds: DocumentServicesObject,
    serializedCompStructures: SerializedCompStructure[],
  ): Promise<void> {
    if (!this.headlessInstallApps) {
      return;
    }
    const uniquePageAppDefIds = [
      ...new Set(
        serializedCompStructures

          .map((structure) => retrieveAppDefIdsFromStructure(structure))
          .flat(),
      ),
    ]
      .filter((id) => ALLOWED_HEADLESS_INSTALL_APP_DEF_IDS.has(id))
      .filter((id) => !isAppInstalled(ds, id));
    if (!uniquePageAppDefIds.length) {
      return;
    }
    const appsInstallOptions = this.getAppsInstallOptions(
      ds,
      uniquePageAppDefIds,
    );
    ErrorReporter.breadcrumb('headlessInstallApps', {
      uniquePageAppDefIds,
      appsInstallOptions,
    });
    try {
      await this.headlessInstallApps(
        uniquePageAppDefIds,
        appsInstallOptions,
        getSiteGeneratorPlatformOrigin(this.shouldUseSgInstallOrigin),
      );
    } catch (e: any) {
      this.reportError(e);
    }
  }

  @logger('sg_add_as_homepage')
  async addAsHomepage(
    ds: DocumentServicesObject,
    generatedHomepage: GeneratedHomepage,
    layoutFamilyDefinition: LayoutFamilyDefinition,
    kitDefinition: KitDefinition,
  ): Promise<CompRef | null> {
    const { name, header, sections, footer } = generatedHomepage;
    const presetsToCheckWidgetsInstalled = [
      ...sections.map(({ structure }) => structure),
      footer?.structure,
    ].filter((s): s is SerializedCompStructure => isExist(s));
    await this.verifyWidgetsInstalled(ds, presetsToCheckWidgetsInstalled);
    setUsesNewAnimations(ds);
    const currentHomepageId = ds.homePage.get();
    const pageRef = await ds.transactions.run(async () => {
      const logoColorPalette = this.getLogoColorPalette();
      setSiteTheme(ds, kitDefinition, layoutFamilyDefinition, logoColorPalette);
      adjustAndReplaceHeader(ds, header);
      replaceFooter(ds, footer?.structure);
      const pageRef = this.addHomePageStructure(ds, name, generatedHomepage);
      if (!pageRef) {
        return null;
      }
      ds.homePage.set(pageRef.id);
      return pageRef;
    });
    if (!pageRef) {
      return null;
    }
    await navigateToPage(ds, pageRef);
    await removePreviousPage(ds, currentHomepageId);
    return pageRef;
  }

  @logger('sg_adjust_mobile')
  private async adjustMobile(
    ds: DocumentServicesObject,
    allPages: AddedPageWithRef[],
  ): Promise<void> {
    await syncAndResetMobileLayout(ds);
    for (const { pageRef, page } of allPages.values()) {
      await runPageMobileAdjustments(ds, pageRef, page, this.isDebugMode);
    }
  }

  @logger('sg_run_post_generation')
  @dsDebugTrace
  async runPostGeneration(
    ds: DocumentServicesObject,
    allPages: AddedPageWithRef[],
  ) {
    const pages = allPages.filter((page) => !!page.pageRef?.id);
    reorderSitePages(ds, pages);
    await addSectionsToMenuIfNeeded(ds, pages[0].pageRef);
    await this.adjustMobile(ds, pages);
    await removeEmptyTransparentBoxes(ds, pages);
    ds.history.clear();
  }

  async injectKit<T extends GeneratedHomepage | GeneratedPage>(
    structure: T,
    layoutFamily: LayoutFamilyDefinition,
    kitDefinition: KitDefinition,
    kitInjectionOptions: KitInjectionOptions,
  ): Promise<T> {
    try {
      this.interactionStarted('sg_inject_kit');
      if (!kitDefinition) {
        throw new Error('no kitDefinition provided to injectKit');
      }
      if (!structure.sections) {
        throw new Error('no page sections provided to injectKit');
      }
      const isHomepage = isGeneratedHomepageStructure(structure);
      const { header, footer, sections, name } = isHomepage
        ? structure
        : { ...structure, header: null, footer: null };
      const injectedKitSiteStructure = await injectKitIntoStructure(
        kitDefinition,
        getKitSiteStructure({ header, sections, footer }),
        this.staticMediaUrl,
        getFullKitInjectionOptions(layoutFamily, kitInjectionOptions),
      );
      const injectedStructure = {
        name,
        sections: sections.map((section, index) => ({
          ...section,
          ...injectedKitSiteStructure.sections[index],
        })),
        ...(isHomepage
          ? {
              header: header && {
                ...header,
                ...injectedKitSiteStructure.header,
              },
              footer: footer && {
                ...footer,
                ...injectedKitSiteStructure.footer,
              },
            }
          : {}),
      } as T;
      this.interactionEnded('sg_inject_kit');
      return injectedStructure;
    } catch (e) {
      this.reportError(
        e as Error,
        { siteGenerationApiFlow: true, injectKit: true },
        {
          industryId: this.industryId,
          structureId: this.structureId,
          kitTitle: kitDefinition?.title,
          kitInjectionOptions,
          userData: this.userData,
          layoutFamily: layoutFamily.title,
        },
      );
      return structure;
    }
  }

  @logger('sg_set_chosen_apps')
  setChosenApps(appDefIds: string[]) {
    if (!appDefIds || !Array.isArray(appDefIds)) {
      throw new Error('appDefIds must be an array');
    }
    ErrorReporter.breadcrumb('setChosenApps', { appDefIds });
    this.appDefIds = appDefIds;
  }

  getChosenApps(): string[] {
    return [...this.appDefIds];
  }

  async getSections(
    ceType: CeType,
    layoutFamily: string,
  ): Promise<PresetStructure[]> {
    const contentCategory = getContentCategoryByCeType(ceType);
    const presets = await getSectionsPresets(
      contentCategory,
      layoutFamily,
      this.useStagingMode,
      this.presetsBundleBaseUrl,
    );
    const presetStructures = await Promise.all(
      presets.map(({ presetJsonUrl, presetMobileJsonUrl }) =>
        fetchPresetStructures([presetJsonUrl, presetMobileJsonUrl]),
      ),
    );
    return presetStructures.map(([structure, mobileStructure], i) => ({
      title: presets[i].title,
      id: presets[i]._id,
      ceType,
      structure,
      mobileStructure,
      contentCategory,
      sourceTemplateId: '',
    }));
  }

  private addPageStructure(
    ds: DocumentServicesObject,
    pageName: string,
    sections: SectionPresetStructure[],
  ): CompRef | null {
    const filteredSections = sections.filter(canSectionPresetBeAdded);
    if (filteredSections.length === 0) {
      return null;
    }
    const uniquePageUriSeo = getUniquePageName(ds, pageName);
    const pageStructure = createPageStructure(
      uniquePageUriSeo,
      filteredSections,
      false,
    );
    fixPresetStructureApplicationIds(ds, pageStructure);
    ErrorReporter.breadcrumb('addPageStructure', {
      pageName,
      uniquePageUriSeo,
    });
    return ds.pages.add(pageName, pageStructure);
  }

  private addHomePageStructure(
    ds: DocumentServicesObject,
    pageName: string,
    generatedHomepage: GeneratedHomepage,
  ): CompRef | null {
    const { sections } = generatedHomepage;
    if (sections.length === 0) {
      return null;
    }
    const customAppPageRef = handleCustomAppPageAsHomepage(
      ds,
      this.gatherSilentInstallApps(generatedHomepage),
      sections,
    );
    if (customAppPageRef) {
      return customAppPageRef;
    }
    const pageRef = this.addPageStructure(ds, pageName, sections);
    if (pageRef) {
      setPageTextsSource(ds, pageRef);
    }
    return pageRef;
  }

  @logger('sg_add_page')
  async addPage(
    ds: DocumentServicesObject,
    generatedPage: GeneratedPage,
  ): Promise<CompRef | null> {
    const { name, sections } = generatedPage;
    const presetsToCheckWidgetsInstalled = [
      ...sections.map(({ structure }) => structure),
    ].filter((s): s is SerializedCompStructure => isExist(s));
    await this.verifyWidgetsInstalled(ds, presetsToCheckWidgetsInstalled);
    const pageRef = await ds.transactions.run(async () => {
      return this.addPageStructure(ds, name, sections);
    });
    if (!pageRef) {
      return null;
    }
    return pageRef;
  }

  @verifyInitContent
  async getIndustryProfiles(): Promise<IndustryProfileData[]> {
    return this.contentProvider.getIndustryProfiles();
  }

  @verifyInitContent
  setIndustryProfile(profileId: string): void {
    ErrorReporter.breadcrumb('setIndustryProfile', { profileId });
    this.contentProvider.setProfileId(profileId);
  }

  getCurrentIndustryProfile(): IndustryProfileData | null {
    return this.contentProvider.getCurrentProfile();
  }

  @verifyInitContent
  getAppsInstallOptions(
    ds: DocumentServicesObject,
    appDefIds: string[],
  ): Record<string, AppInstallOption> {
    const contentAppsOptions =
      this.contentProvider.getAppsInstallOptions(appDefIds);
    const pendingAppsIds = new Set(
      ds.tpa.getPendingApps().map(({ appDefinitionId }) => appDefinitionId),
    );
    appDefIds.forEach((appDefId) => {
      if (pendingAppsIds.has(appDefId) && contentAppsOptions[appDefId]) {
        contentAppsOptions[appDefId].sourceTemplateId = '';
      }
    });
    return contentAppsOptions;
  }

  gatherSilentInstallApps({
    header,
    sections,
    footer,
  }: GeneratedHomepage): string[] {
    const appDefIds: string[] = [];
    if (header) {
      appDefIds.push(...retrieveAppDefIdsFromStructure(header.structure));
    }
    if (footer) {
      appDefIds.push(...retrieveAppDefIdsFromStructure(footer.structure));
    }
    sections.forEach(({ structure }) => {
      appDefIds.push(...retrieveAppDefIdsFromStructure(structure));
    });
    return [
      ...new Set(
        [...appDefIds, ...this.appDefIds]
          .map(replaceStoresIfNeeded)
          .filter((appDefId) => ALLOWED_SILENT_INSTALLATION_APPS.has(appDefId)),
      ),
    ];
  }

  reportError(
    error: Error,
    tags?: Record<string, any>,
    extra?: Record<string, any>,
  ) {
    ErrorReporter.captureException(error, {
      tags: {
        siteGenerationApiFlow: true,
        ...tags,
      },
      extra,
    });
    if (!this.isDebugMode) {
      return;
    }
    console.error('reportError', error);
  }
}
