import type {
  SerializedCompStructure,
  StyleRef,
} from '@wix/document-services-types';
import type { KitDefinition } from '../../types';
import { isBackground } from '@wix/editor-content-injector';
import { wrapInnerTextWithSpanInlineColor } from '../../utils';
import _ from 'lodash';

interface ImageUrlToComponentsMap {
  [imageBgUrl: string]: {
    transparentImage: boolean;
    texts: SerializedCompStructure[];
    buttons: SerializedCompStructure[];
  };
}

export const getComponentsOnImageBG = (
  structure: SerializedCompStructure,
  kitDefinition: KitDefinition,
  parentWithBackground: SerializedCompStructure | null = null,
): ImageUrlToComponentsMap => {
  const aggregator = {};
  getComponentsOnImageBG_Recursive(
    structure,
    kitDefinition,
    parentWithBackground,
    aggregator,
  );

  Object.keys(aggregator).forEach((url) => {
    if (aggregator[url].length === 0) {
      delete aggregator[url];
    }
  });

  return aggregator;
};

export const setTextColor = (text: SerializedCompStructure, color: string) => {
  if (!text?.data) {
    return;
  }
  if (text.data.text.includes('color_')) {
    text.data.text = text.data.text.replace(/color_\d+/g, color);
  } else {
    text.data.text = wrapInnerTextWithSpanInlineColor(text.data.text, color);
  }
};

export const setButtonColor = (
  button: SerializedCompStructure,
  kitDefinition: KitDefinition,
  mainColor: string,
  secondaryColor: string,
) => {
  let styleObject: StyleRef;
  if (typeof button.style === 'string') {
    styleObject = _.cloneDeep(
      kitDefinition.themeStyles[button.style],
    ) as StyleRef;
  } else {
    styleObject = button.style as StyleRef;
  }

  if (styleObject) {
    styleObject.style.properties = {
      ...styleObject.style.properties,
      'alpha-bg': '0',
      'alpha-bgh': '1',
      'alpha-brd': '1',
      'alpha-brdh': '1',
      bg: mainColor,
      bgh: mainColor,
      brd: mainColor,
      brdh: mainColor,
      brw: '1',
      txt: mainColor,
      txth: secondaryColor,
    };

    styleObject.style.propertiesSource = {
      ...styleObject.style.propertiesSource,
      'alpha-bg': 'value',
      'alpha-bgh': 'value',
      'alpha-brd': 'value',
      'alpha-brdh': 'value',
      bg: 'value',
      bgh: 'value',
      brd: 'value',
      brdh: 'value',
      brw: 'value',
      txt: 'value',
      txth: 'value',
    };
    delete styleObject.id;
    styleObject.styleType = 'custom';
    button.style = styleObject;
  }
};

const getComponentsOnImageBG_Recursive = (
  structure: SerializedCompStructure,
  kitDefinition: KitDefinition,
  parentWithBackground: SerializedCompStructure | null = null,
  aggregator: ImageUrlToComponentsMap,
) => {
  const hasBackground = isBackground(structure);
  if (
    hasBackground ||
    (isTransparentBG(structure, kitDefinition) && parentWithBackground !== null)
  ) {
    const { url, transparentImage } = hasBackground
      ? getBackgroundUrl(structure)
      : getBackgroundUrl(parentWithBackground as SerializedCompStructure);

    aggregator[url] = {
      transparentImage,
      texts: [
        ...(aggregator[url]?.texts || []),
        ...getTextComponents(structure),
      ],
      buttons: [...(aggregator[url]?.buttons || []), ...getButtons(structure)],
    };
  }
  structure.components?.forEach((component) =>
    getComponentsOnImageBG_Recursive(
      component,
      kitDefinition,
      hasBackground ? structure : parentWithBackground,
      aggregator,
    ),
  );
  return aggregator;
};

const getTextComponents = (structure: SerializedCompStructure) =>
  structure.components?.filter(
    (comp) => comp.componentType === 'wysiwyg.viewer.components.WRichText',
  ) || [];

const getButtons = (structure: SerializedCompStructure) =>
  structure.components?.filter(
    (comp) => comp.componentType === 'wysiwyg.viewer.components.SiteButton',
  ) || [];

const getBackgroundUrl = (structure: SerializedCompStructure) => ({
  url: structure.design?.background?.mediaRef?.uri as string,
  transparentImage: structure.design?.background?.mediaRef?.opacity < 1,
});

const isTransparentBG = (
  structure: SerializedCompStructure,
  kitDefinition: KitDefinition,
) => {
  if (structure.design) {
    const background = structure.design.background;
    const media = background?.mediaRef;
    const colorOpacity =
      background?.colorLayers?.[0].opacity ?? background?.colorOpacity ?? 1;

    const isMediaBGHidden = media?.opacity === 0;
    const isColorBGHidden = colorOpacity === 0;
    return media ? isMediaBGHidden : isColorBGHidden;
  }
  if (typeof structure.style === 'string') {
    return (
      kitDefinition.themeStyles[structure.style]?.style.properties[
        'alpha-bg'
      ] === '0'
    );
  }
};
