import {
  GPT_PALETTE_FROM_PROMPT_VERSION,
  GPT_PROMPT_VERSION_LATEST,
  MAIN_COLORS_NAMES,
} from './constants';
import {
  getFullPalettes,
  createGptLogoParams,
  fetchExtractColorsFromImage,
  fetchGenareteFromImageV2,
  getGptPromptsVersion,
  fetchColorFromPromptV2,
  getColorsPaletteAndLinkedPalette,
  getDependentLinksToUpdate,
  getDependentHexToUpdate,
  getLinkedHexToUpdate,
  fetchGenareteFromImageAndCurrentPalette,
} from './utils';
import { GptPalette, LinkedColorValue, PartialColorPalette } from './types';
import { convertColorPaletteToGPTParam } from '../colors/utils';
import { ColorPalette } from '@wix/document-services-types';

export const getColorsFromImage = async ({
  imagePath,
  metaSiteInstanceId,
  selectedGptPromptsVersion = GPT_PROMPT_VERSION_LATEST,
}: {
  imagePath: string;
  metaSiteInstanceId: string;
  selectedGptPromptsVersion?: string;
}): Promise<{
  fullPalettes: PartialColorPalette[];
  gptPromptsVersion: string;
}> => {
  const colorsFromColorExtractor = await fetchExtractColorsFromImage(imagePath);
  if (!colorsFromColorExtractor) {
    throw new Error('No colors found in image');
  }

  const gptPromptsVersion =
    selectedGptPromptsVersion || getGptPromptsVersion(colorsFromColorExtractor);
  const gptParamsData = createGptLogoParams(colorsFromColorExtractor);

  let gptResponse;
  let gptPalettes;

  gptResponse = await fetchGenareteFromImageV2(
    metaSiteInstanceId,
    gptParamsData,
  );
  gptPalettes = JSON.parse(gptResponse.data.response.generatedTexts);

  const fullPalettes = getFullPalettes(gptPalettes, MAIN_COLORS_NAMES);

  return { fullPalettes, gptPromptsVersion };
};

export const getColorsFromPrompt = async ({
  currentPalette,
  userRequestMessage,
  metaSiteInstanceId,
  selectedGptPromptsVersion = GPT_PALETTE_FROM_PROMPT_VERSION,
}: {
  currentPalette: PartialColorPalette;
  metaSiteInstanceId: string;
  userRequestMessage: string;
  selectedGptPromptsVersion?: string;
}): Promise<{
  fullPalettes: PartialColorPalette[];
  gptPromptsVersion: string;
}> => {
  const gptPromptsVersion = selectedGptPromptsVersion;
  const currentColors = convertColorPaletteToGPTParam(currentPalette);
  if (!currentColors) {
    throw new Error('No colors found, Failed convertColorPaletteToGPTParam');
  }
  let gptData;
  let gptPalettes;

  const requestParams = {
    CURRENT_USER_PALETTE: currentColors,
    USER_REQUEST_MESSAGE: userRequestMessage,
  };

  gptData = await fetchColorFromPromptV2(metaSiteInstanceId, requestParams);
  gptPalettes = JSON.parse(gptData.data.response.generatedTexts);

  const fullPalettes = getFullPalettes(gptPalettes, MAIN_COLORS_NAMES);

  return { fullPalettes, gptPromptsVersion };
};

export const getKitAndLogoMixedPalette = (
  currentMainPalette: ColorPalette,
  currentLinkedPalette: Partial<
    Record<keyof ColorPalette, LinkedColorValue> | undefined
  >,
  promptPalette: Partial<ColorPalette>,
) => {
  const {
    updateMainColorFromPromptPalette,
    updateLinkedColorFromPromptPalette,
  } = getColorsPaletteAndLinkedPalette(promptPalette);
  const dependentLinksToUpdate = getDependentLinksToUpdate(
    updateLinkedColorFromPromptPalette,
    currentLinkedPalette,
  );
  const allLinksToUpdate = {
    ...currentLinkedPalette,
    ...updateLinkedColorFromPromptPalette,
    ...dependentLinksToUpdate,
  };
  const dependentHexToUpdate = getDependentHexToUpdate(
    updateMainColorFromPromptPalette,
    allLinksToUpdate,
  );
  const hexUpdates = {
    ...updateMainColorFromPromptPalette,
    ...dependentHexToUpdate,
  };
  const linkedHexToUpdate = getLinkedHexToUpdate(
    updateLinkedColorFromPromptPalette,
    dependentLinksToUpdate,
    hexUpdates,
    currentMainPalette,
  );
  return {
    colorSiteTheme: { ...hexUpdates, ...linkedHexToUpdate },
    linkedColorSiteTheme: { ...allLinksToUpdate },
  };
};

const jsonParsing = <T>(stringToParse: string) => {
  try {
    const jsonValue: T = JSON.parse(stringToParse);
    return jsonValue;
  } catch (e) {
    throw new Error(`Invalid JSON: ${stringToParse}`);
  }
};

export const getColorsFromImageAndCurrentPalette = async ({
  imagePath,
  currentPalette,
  metaSiteInstanceId,
}: {
  imagePath: string;
  currentPalette: PartialColorPalette;
  metaSiteInstanceId: string;
}): Promise<{
  fullPalettes: PartialColorPalette[];
}> => {
  const colorsFromColorExtractor = await fetchExtractColorsFromImage(imagePath);
  if (!colorsFromColorExtractor) {
    throw new Error('No colors found in image');
  }
  const colorsFromImageToGpt = createGptLogoParams(colorsFromColorExtractor);
  const currentColors = convertColorPaletteToGPTParam(currentPalette, false);
  if (!currentColors) {
    throw new Error('No colors found, Failed convertColorPaletteToGPTParam');
  }

  const gptResponse = await fetchGenareteFromImageAndCurrentPalette(
    metaSiteInstanceId,
    colorsFromImageToGpt,
    currentColors,
  );
  const gptPalettes = jsonParsing<GptPalette[]>(
    gptResponse.data.response.generatedTexts,
  );

  const fullPalettes = getFullPalettes(gptPalettes, MAIN_COLORS_NAMES);

  return { fullPalettes };
};
