import _ from 'lodash';
import {
  applyMediaItemCropData,
  mapStructureByCompType,
  mapStructureById,
} from '../utils';
import {
  isFieldContentRole,
  isTransparentVideo,
} from '@wix/editor-content-injector';

import type {
  TransparentVideoKit,
  KitDefinition,
  KitPresetStructures,
} from '../types';
import type { SerializedCompStructure } from '@wix/document-services-types';

// TODO: use from component types package once created
const mediaPlayerType = 'wysiwyg.viewer.components.MediaPlayer';

function replaceTransparentVideo(
  mediaPlayerStructure: SerializedCompStructure,
  transparentVideoKit: TransparentVideoKit,
  shouldOverrideChildren: boolean,
): void {
  const mediaPlayerLayout = mediaPlayerStructure.layout;
  if (!mediaPlayerLayout) {
    // TODO: implement for one doc
    console.error(
      'could not replace transparent video, no layout in structure',
    );
    return;
  }
  mediaPlayerStructure.design = {
    ...mediaPlayerStructure.design,
    ..._.pick(transparentVideoKit.design, ['background', 'cssStyle']),
  };
  mediaPlayerStructure.props = {
    ...mediaPlayerStructure.props,
    ..._.pick(transparentVideoKit.props, [
      'loop',
      'type',
      'autoplay',
      'playerAudioInteraction',
      'disableAudio',
      'mute',
      'animatePoster',
      'flip',
      'playerInteraction',
    ]),
  };
  if (shouldOverrideChildren && mediaPlayerStructure.components) {
    mediaPlayerStructure.components = mediaPlayerStructure.components.map(
      (structure, index) => ({
        // TODO: need a better solution to remove inner components data items ids
        ..._.cloneDeepWith(transparentVideoKit.components[index], (obj) =>
          _.omit(obj, 'id'),
        ),
        id: structure.id,
      }),
    );
  }

  applyMediaItemCropData(mediaPlayerLayout, {
    height: transparentVideoKit.dimensions.height,
    width: transparentVideoKit.dimensions.width,
  });
}

export const setTransparentVideos = (
  { structure, mobileStructure }: KitPresetStructures,
  transparentVideoKit: KitDefinition['transparentVideo'],
): void => {
  if (!transparentVideoKit) {
    return;
  }
  const mobileStructureById = mapStructureById(mobileStructure);
  mapStructureByCompType(structure)[mediaPlayerType]?.forEach(
    (mediaPlayerStructure) => {
      if (
        !isFieldContentRole(mediaPlayerStructure.contentRole!) ||
        !isTransparentVideo(mediaPlayerStructure) ||
        !mediaPlayerStructure.layout ||
        !mediaPlayerStructure.id
      ) {
        return;
      }

      replaceTransparentVideo(mediaPlayerStructure, transparentVideoKit, true);
      const mobileTransparentVideo =
        mobileStructureById[mediaPlayerStructure.id];
      if (mobileTransparentVideo) {
        replaceTransparentVideo(
          mobileTransparentVideo,
          transparentVideoKit,
          false,
        );
      }
    },
  );
};
