import styles from './previewerItem.scss';
import React, {
  useState,
  useEffect,
  useRef,
  type JSXElementConstructor,
} from 'react';
import { isFirefox } from '@wix/santa-editor-utils';

export interface DisplayConfig {
  displayWidth?: number;
  baseWidth?: number;
  displayMaxHeight?: number;
  targetDefaultHeight?: number;
  shouldOverlay?: boolean;
  isInteractive?: boolean;
  isScrollable?: boolean;
  isClickable?: boolean;
  onReady?: () => void;
}

interface PreviewerComponentProps extends DisplayConfig {
  ThumbnailComponent: JSXElementConstructor<{ onReady: Function }>;
}

function getDefaultBaseWidth(): number {
  return Math.max(
    window.document.body.getBoundingClientRect().width - 100,
    980,
  );
}

// TODO: handle resize
export const PreviewerItem: React.FC<PreviewerComponentProps> = React.memo(
  ({
    ThumbnailComponent,
    displayWidth,
    baseWidth = getDefaultBaseWidth(),
    displayMaxHeight,
    targetDefaultHeight = 100,
    shouldOverlay = true,
    isInteractive = false,
    isScrollable = false,
    isClickable = true,
    onReady,
  }: PreviewerComponentProps) => {
    const wrapperRef = useRef(null);
    const [scale, setScale] = useState(1);
    const [displayHeight, setDisplayHeight] =
      useState<number>(targetDefaultHeight);
    const [innerHeight, setInnerHeight] = useState<number>(targetDefaultHeight);
    const [ready, setReady] = useState(false);

    const onViewerDone = () => {
      setReady(true);
      onReady?.();
    };

    useEffect(
      () => {
        if (wrapperRef.current) {
          const resizeObserver = new ResizeObserver(() => {
            const scale = displayWidth / baseWidth;
            setScale(scale);
            let height = wrapperRef?.current?.clientHeight * scale;
            setInnerHeight(height);
            if (displayMaxHeight) {
              height = height > displayMaxHeight ? displayMaxHeight : height;
            }
            setDisplayHeight(height);
          });
          resizeObserver.observe(wrapperRef.current);
          return () => resizeObserver.disconnect();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [wrapperRef?.current?.clientHeight],
    );

    // This is a fix for vertical lines that show when scaled site is rendered in any browser besides Firefox
    // zoom works well in all browsers except Firefox, so we use transform: scale() for Firefox
    const zoomOrScalePropStyle = isFirefox()
      ? {
          transform: `scale(${scale})`,
        }
      : {
          zoom: scale,
        };

    return (
      <div
        data-aid="previewer-item"
        style={{
          height: displayHeight,
          width: displayWidth,
          overflow: isScrollable ? 'scroll' : 'hidden',
        }}
      >
        <div
          style={{
            maxHeight: innerHeight,
            maxWidth: displayWidth,
            overflow: 'hidden',
          }}
        >
          <div
            className={styles.item}
            ref={wrapperRef}
            style={{
              minHeight: targetDefaultHeight,
              width: baseWidth,
              opacity: ready ? 1 : 0,
              ...zoomOrScalePropStyle,
            }}
          >
            <ThumbnailComponent onReady={onViewerDone} />
            {shouldOverlay && (
              <div
                style={{
                  pointerEvents: isInteractive ? 'none' : 'auto',
                  cursor: isClickable ? 'pointer' : 'default',
                }}
                className={styles.overlay}
              />
            )}
          </div>
        </div>
      </div>
    );
  },
  () => true,
);

PreviewerItem.displayName = 'PreviewerItem';
